@use "./variables" as *;

[class^="hs-icon-"], [class*=" hs-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-hs-icon !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hs-icon-area-chart:before {
  content: "\e981";
}

.hs-icon-arrow-down:before {
  content: "\e901";
}

.hs-icon-arrow-left:before {
  content: "\e908";
}

.hs-icon-arrow-right:before {
  content: "\e909";
}

.hs-icon-arrow-up:before {
  content: "\e90c";
}

.hs-icon-arrows-back-forth:before {
  content: "\e959";
}

.hs-icon-arrows-drag:before {
  content: "\e98a";
}

.hs-icon-bell:before {
  content: "\e95a";
}

.hs-icon-bell-fill:before {
  content: "\e967";
}

.hs-icon-bell-fill2:before {
  content: "\e96a";
}

.hs-icon-bell-fill3:before {
  content: "\e966";
}

.hs-icon-bookmark:before {
  content: "\e91b";
}

.hs-icon-bookmark-fill:before {
  content: "\e948";
}

.hs-icon-briefcase:before {
  content: "\e92e";
}

.hs-icon-calculate-delta:before {
  content: "\e96e";
}

.hs-icon-calculate-n:before {
  content: "\e931";
}

.hs-icon-calculate-percent:before {
  content: "\e93d";
}

.hs-icon-calculate-plus-minus:before {
  content: "\e964";
}

.hs-icon-calculator:before {
  content: "\e97c";
}

.hs-icon-calculator-outline:before {
  content: "\e97d";
}

.hs-icon-calendar:before {
  content: "\e92b";
}

.hs-icon-calendar-blank2:before {
  content: "\e968";
}

.hs-icon-calendar-check:before {
  content: "\e92f";
}

.hs-icon-calendar-day:before {
  content: "\e930";
}

.hs-icon-chart-arrow:before {
  content: "\e96d";
}

.hs-icon-check:before {
  content: "\e917";
}

.hs-icon-check-circle:before {
  content: "\e905";
}

.hs-icon-check-for-checkbox:before {
  content: "\e972";
}

.hs-icon-check-mini:before {
  content: "\e90f";
}

.hs-icon-checkbox-checked:before {
  content: "\e96b";
}

.hs-icon-checkbox-indeterminate:before {
  content: "\e96c";
}

.hs-icon-checkbox-unchecked:before {
  content: "\e974";
}

.hs-icon-chevron-double-left:before {
  content: "\e90d";
}

.hs-icon-chevron-double-right:before {
  content: "\e90e";
}

.hs-icon-chevron-down:before {
  content: "\e911";
}

.hs-icon-chevron-down2:before {
  content: "\e973";
}

.hs-icon-chevron-left:before {
  content: "\e912";
}

.hs-icon-chevron-right:before {
  content: "\e913";
}

.hs-icon-chevron-up:before {
  content: "\e914";
}

.hs-icon-circle:before {
  content: "\e916";
}

.hs-icon-clock:before {
  content: "\e975";
}

.hs-icon-close:before {
  content: "\e906";
}

.hs-icon-close-circle:before {
  content: "\e971";
}

.hs-icon-cloud:before {
  content: "\e928";
}

.hs-icon-cloud-download:before {
  content: "\e93f";
}

.hs-icon-cloud-save:before {
  content: "\e995";
}

.hs-icon-cloud-upload:before {
  content: "\e990";
}

.hs-icon-cog:before {
  content: "\e919";
}

.hs-icon-cog-outline:before {
  content: "\e91a";
}

.hs-icon-comment:before {
  content: "\e900";
}

.hs-icon-comment-blank-fill:before {
  content: "\e963";
}

.hs-icon-comment-blank-outline:before {
  content: "\e976";
}

.hs-icon-comment-hide:before {
  content: "\e91c";
}

.hs-icon-contract:before {
  content: "\e988";
}

.hs-icon-copy:before {
  content: "\e910";
}

.hs-icon-credit-card:before {
  content: "\e939";
}

.hs-icon-database:before {
  content: "\e965";
}

.hs-icon-dollar:before {
  content: "\e958";
}

.hs-icon-donut-fill:before {
  content: "\e95c";
}

.hs-icon-donut-outline:before {
  content: "\e95d";
}

.hs-icon-dots-horizontal:before {
  content: "\e90a";
}

.hs-icon-dots-vertical:before {
  content: "\e90b";
}

.hs-icon-download:before {
  content: "\e934";
}

.hs-icon-edit:before {
  content: "\e91e";
}

.hs-icon-edit-pencil:before {
  content: "\e91f";
}

.hs-icon-email:before {
  content: "\e904";
}

.hs-icon-error:before {
  content: "\e955";
}

.hs-icon-expand:before {
  content: "\e927";
}

.hs-icon-eye:before {
  content: "\e97b";
}

.hs-icon-eye-private:before {
  content: "\e97a";
}

.hs-icon-filter:before {
  content: "\e91d";
}

.hs-icon-filter-plus:before {
  content: "\e922";
}

.hs-icon-filter-slider:before {
  content: "\e923";
}

.hs-icon-filter-stack:before {
  content: "\e926";
}

.hs-icon-format-code:before {
  content: "\e94f";
}

.hs-icon-format-csv:before {
  content: "\e96f";
}

.hs-icon-format-excel:before {
  content: "\e952";
}

.hs-icon-format-excel-fill:before {
  content: "\e950";
}

.hs-icon-format-jpg:before {
  content: "\e95f";
}

.hs-icon-format-pdf:before {
  content: "\e94d";
}

.hs-icon-format-pdf-fill:before {
  content: "\e94c";
}

.hs-icon-format-png:before {
  content: "\e960";
}

.hs-icon-format-text:before {
  content: "\e93e";
}

.hs-icon-format-word:before {
  content: "\e94b";
}

.hs-icon-format-word-fill:before {
  content: "\e945";
}

.hs-icon-format-xls:before {
  content: "\e970";
}

.hs-icon-grid:before {
  content: "\e979";
}

.hs-icon-group-contractor:before {
  content: "\e97e";
}

.hs-icon-group-expander:before {
  content: "\e97f";
}

.hs-icon-hand-pointing:before {
  content: "\e94e";
}

.hs-icon-hierarchy:before {
  content: "\e98d";
}

.hs-icon-home1:before {
  content: "\e986";
}

.hs-icon-home2:before {
  content: "\e987";
}

.hs-icon-home3:before {
  content: "\e985";
}

.hs-icon-home4:before {
  content: "\e982";
}

.hs-icon-home5:before {
  content: "\e983";
}

.hs-icon-home6:before {
  content: "\e984";
}

.hs-icon-home7:before {
  content: "\e989";
}

.hs-icon-horizontal-bar:before {
  content: "\e957";
}

.hs-icon-image:before {
  content: "\e907";
}

.hs-icon-info:before {
  content: "\e93b";
}

.hs-icon-institution:before {
  content: "\e953";
}

.hs-icon-interval-back-one:before {
  content: "\e99a";
}

.hs-icon-interval-fast-forward:before {
  content: "\e994";
}

.hs-icon-interval-forward-one:before {
  content: "\e997";
}

.hs-icon-interval-rewind:before {
  content: "\e993";
}

.hs-icon-investor:before {
  content: "\e954";
}

.hs-icon-line-chart:before {
  content: "\e95e";
}

.hs-icon-line-chart-points:before {
  content: "\e949";
}

.hs-icon-list:before {
  content: "\e980";
}

.hs-icon-lock:before {
  content: "\e956";
}

.hs-icon-logout:before {
  content: "\e918";
}

.hs-icon-menu:before {
  content: "\e920";
}

.hs-icon-minus-circle:before {
  content: "\e98b";
}

.hs-icon-moon:before {
  content: "\e921";
}

.hs-icon-open:before {
  content: "\e915";
}

.hs-icon-paper:before {
  content: "\e940";
}

.hs-icon-paperclip:before {
  content: "\e93c";
}

.hs-icon-pause:before {
  content: "\e992";
}

.hs-icon-people:before {
  content: "\e947";
}

.hs-icon-person:before {
  content: "\e902";
}

.hs-icon-person-fill:before {
  content: "\e942";
}

.hs-icon-piechart:before {
  content: "\e961";
}

.hs-icon-piechart-fill:before {
  content: "\e962";
}

.hs-icon-piechart-pattern:before {
  content: "\e95b";
}

.hs-icon-plant:before {
  content: "\e944";
}

.hs-icon-play:before {
  content: "\e991";
}

.hs-icon-plus:before {
  content: "\e903";
}

.hs-icon-plus-circle:before {
  content: "\e929";
}

.hs-icon-redo:before {
  content: "\e936";
}

.hs-icon-refresh:before {
  content: "\e92d";
}

.hs-icon-report:before {
  content: "\e946";
}

.hs-icon-resizer:before {
  content: "\e98c";
}

.hs-icon-row-groups:before {
  content: "\e978";
}

.hs-icon-ruler:before {
  content: "\e969";
}

.hs-icon-search:before {
  content: "\e92a";
}

.hs-icon-search-right:before {
  content: "\e92c";
}

.hs-icon-send:before {
  content: "\e932";
}

.hs-icon-sidebar:before {
  content: "\e977";
}

.hs-icon-smiley:before {
  content: "\e935";
}

.hs-icon-star:before {
  content: "\e93a";
}

.hs-icon-star-fill:before {
  content: "\e933";
}

.hs-icon-sun-fill:before {
  content: "\e924";
}

.hs-icon-sun-outline:before {
  content: "\e925";
}

.hs-icon-transaction:before {
  content: "\e943";
}

.hs-icon-trashcan:before {
  content: "\e937";
}

.hs-icon-triangle-down:before {
  content: "\e98e";
}

.hs-icon-triangle-up:before {
  content: "\e98f";
}

.hs-icon-undo:before {
  content: "\e938";
}

.hs-icon-vertical-bar:before {
  content: "\e941";
}

.hs-icon-vertical-stacked-bar:before {
  content: "\e94a";
}

.hs-icon-vertical-stacked-bar2:before {
  content: "\e951";
}
