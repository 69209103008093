@use "./variables" as *;
@use "./mixin" as *;
@use "./extend" as *;

.widget {
    background: var(--main-background);
    position: absolute;
    -webkit-touch-callout: none;
    user-select: none;

    &#{&}-selected:not(.view-mode) {
        box-shadow: $common-box-shadow;
    }

    &:not(&#{&}-selected):not(.view-mode),
    &.view-mode:not(:hover) {
        .widget-actions .menu-options {
            display: none;
        }
    }
}

.widget-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
}

.widget-header {
    display: flex;
    flex: 0 30px;
    border-bottom: 2px solid var(--mw-border);
    cursor: move;
    line-height: 30px;

    .hide-header:not(.view-mode):not(.widget-selected) &,
    .view-mode.hide-header:not(:hover) & {
        display: none;
    }
}

.widget-title {
    @include font-size-small;
    @extend %ellipsis;
    font-weight: bold;
    color: var(--text-highlight);
    flex: 0 auto;
    max-width: 50%;
}

.widget-actions {
    flex: 1 auto;
    text-align: right;
    pointer-events: none;

    .iconGroup {
        float: right;
        font-size: 1.25rem;
        pointer-events: initial;

        &:not(.custom-filter) {
            margin-right: 4px;
        }

        &.custom-filter {
            margin-left: 4px;
        }

        &.disabled {
            pointer-events: none;
            opacity: .4;
        }

        &.menuBtn,
        &.custom-filter {
            float: left;

            &.disabled {
                opacity: 0;
            }
        }
    }
}

.container .content {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 3px;

    .hide-header:not(.view-mode):not(.widget-selected) &,
    .view-mode.hide-header:not(:hover) & {
        top: 0;
        bottom: 30px;
    }
}

.live-demo-widget {
    .clearfix.maximized.compare-enabled .vizChart,
    .clearfix.maximized.compare-enabled .vizChart-table {
        margin-top: 35px;
    }
}
