@use "./variables" as *;
@use "./mixin" as *;

input,
textarea {
    &, &:read-only {
        background: var(--input-fill);
        color: var(--input-text);
        font-weight: bold;
        padding: 5px 8px;

        border: 1px solid var(--input-border);

        &.no-fill {
            background: none;
        }

        &::placeholder {
            font-weight: normal;
            font-style: italic;
            color: var(--input-placeholder-text);
        }

        // overriding HS Core Components styles start
        &.ng-invalid {
            border: 1px solid var(--input-border);
        }
        // overriding HS Core Components styles end

        &:focus {
            border: 1px solid var(--input-border-focus);
        }

        &:-webkit-autofill {
            &, &:hover, &:active, &:focus {
                -webkit-text-fill-color: var(--text-highlight);
                background: var(--input-fill);
                transition: background-color 10000s ease-in-out 0s;

                &::first-line {
                    font-weight: bold;
                }
            }
        }
    }
}

textarea {
    resize: vertical;
    width: 100%;
    overflow: auto;

    &[formControlName="description"] {
        height: 80px;
    }
}

button {
    font-weight: bold;
    background: var(--button-fill);
    border: 1px solid transparent;
    cursor: pointer;
    padding: 5px 12px;
    transition: background-color .2s, border .2s, color .2s;

    &:not(.naked-btn) {
        text-transform: uppercase;
    }

    &[disabled] {
        cursor: not-allowed;
        pointer-events: none;
    }

    &.fill-btn {
        color: var(--button-text-hover);
        background: var(--button-text);
        border-color: var(--button-text);

        &:hover:not([disabled]) {
            background: var(--button-border-alt);
            border-color: var(--button-border-alt);
        }

        &[disabled] {
            color: var(--button-disable-text-alt);
            background: var(--button-disable-border-alt);
            border-color: var(--button-disable-border-alt);
        }
    }

    &.ghost-btn {
        background: none;
        border-color: var(--button-ghost-border);
        color: var(--button-text);

        &:hover:not([disabled]) {
            color: var(--button-text-hover);
            border-color: var(--button-text);
            background: var(--button-text);
        }

        &[disabled] {
            border-color: var(--button-disable-text);
            color: var(--button-disable-text);
        }
    }

    &.empty-btn {
        border: 0;
        background: 0;
        font-weight: normal;
        color: var(--button-text-alt);
        text-transform: initial;

        &:hover:not([diabled]) {
            color: var(--button-text);
        }

        &[disabled] {
            color: var(--button-disable-text);
        }
    }
}

.toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: 0;

    label {
        flex: 1;

        &:first-of-type {
            text-align: right;
        }

        &.selected {
            font-weight: bold;
            color: var(--text-highlight);
            transition: .4s;
        }
    }

    input[type="checkbox"] {
        display: none;

        &:checked + label:before {
            transform: translateX(22px);
        }

        + label {
            flex: 0 40px;
            height: 16px;
            background: var(--toggle-fill);
            border-radius: 10px;
            margin: 0 6px;
            position: relative;
            cursor: pointer;

            &:before {
                background: var(--toggle-circle);
                position: absolute;
                content: '';
                height: 10px;
                width: 10px;
                top: 3px;
                left: 4px;
                transition: .4s;
                border-radius: 50%;
                transform: translateX(0);
            }
        }

        &.dark-text {
            transition: .4s;
        }
    }
}

.cb-toggle {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 40px;
    height: 16px;
    margin-left: 6px;
    margin-right: 6px;

    input[type="checkbox"] {
        opacity: 0;

        &:focus + .tog-slider {
            box-shadow: 0 0 2px var(--dsd-selected-text);
        }

        &:disabled + .tog-slider {
            background: var(--input-border);
            border-color: var(--input-border);
            cursor: not-allowed;
        }

        &:checked + .tog-slider:before {
            transform: translateX(0px);
        }
    }

    .tog-slider {
        @include posabs;
        border-radius: 10px;
        background: var(--toggle-fill);
        cursor: pointer;
        transition: .4s;

        &:before {
            background: var(--toggle-circle);
            position: absolute;
            content: '';
            height: 10px;
            width: 10px;
            left: 3px;
            bottom: 3px;
            transition: .4s;
            border-radius: 50%;
            transform: translateX(22px);
        }
    }
}

.radio-btn {
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;

    &, input[type="radio"], label {
        cursor: pointer;
    }

    input[type="radio"] {
        margin-right: .327272727272727rem;
        width: 1.273090909090909rem;
        height: 1.273090909090909rem;
        position: relative;
        border-radius: 100%;
        border-color: var(--input-border);

        & + label {
            color: var(--limited-color);
            margin-top: 2px;
        }

        &:before {
            content: '';
            position: absolute;
            border-radius: 100%;
        }

        &:checked {
            border-color: var(--text-highlight);

            & + label {
                color: var(--text-highlight);
                font-weight: bold;
            }

            &:before {
                background: var(--text-highlight);
                width: .727636363636364rem;
                height: .727636363636364rem;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        // this fixes Issue 2 in DDV-6975. It should remain until we fully switch to CC radio
        &:read-only {
            padding: 0;
        }
    }
}
