@use "./variables" as *;

@font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    src: url("#{$assets-fonts}/news_gothic_bt_roman.woff") format('woff')
}

@font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    src: url("#{$assets-fonts}/news_gothic_bt_roman_bold.woff") format('woff')
}

@font-face {
    font-family: $font-family-demi;
    font-style: normal;
    font-weight: normal;
    src: url("#{$assets-fonts}/news_gothic_bt_demi.woff") format('woff')
}

@font-face {
    font-family: $font-family-light;
    font-style: normal;
    font-weight: normal;
    src: url("#{$assets-fonts}/news_gothic_bt_light.woff") format('woff')
}

@font-face {
    font-family: $font-icon;
    font-weight: normal;
    font-style: normal;
    src: url("#{$assets-fonts}/#{$font-icon}.woff") format('woff');
}

@font-face {
    font-family: $font-hs-icon;
    font-weight: normal;
    font-style: normal;
    src: url("#{$assets-fonts}/#{$font-hs-icon}.woff") format('woff');
}
