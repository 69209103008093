@use "./mixin" as *;

%display-inline-block {
    display: inline-block;
    vertical-align: middle;

    &.valignTop {
        vertical-align: top;
    }

    &.valignBottom {
        vertical-align: bottom;
    }
}

%ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

%ellipsis-v-align-top {
    @extend %ellipsis;
    vertical-align: top;
}

%ellipsis-in-filter {
    @extend %ellipsis;
    max-width: 150px;
    display: inline-block;
    vertical-align: middle;
}

%form-label {
    color: var(--main-text-color);
    display: block;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: normal;
}

%widget-header-title-bar {
    .titleBar {
        line-height: 27px;

        &.titleBar[ng-reflect-is-draggable="false"] {
            cursor: auto;
        }

        .title {
            @include font-size-small;
            font-weight: bold;
            color: var(--text-highlight);
            line-height: 22px;
            margin: 0;
            max-width: 50%;
            width: auto;
        }

        div {
            .iconGroup {
                float: right;
                line-height: 20px;
                left: 0;
                padding: 0;

                i {
                    margin: 3px 2px 0;
                    display: none;
                }

                &.iconGroup.menuBtn {
                    float: left;
                }

                &.iconGroup.custom-filter {
                    float: left;
                }
            }
        }
    }
}

%hover-active-viz {
    .icon-simple-grid:before,
    .icon-advancedgrid:before,
    .icon-line-chart:before,
    .icon-donut:before,
    .icon-horizontal-bar:before,
    .icon-horizontal-stacked:before,
    .icon-vertical-stacked:before,
    .icon-pie-chart:before,
    .icon-vertical-bar:before,
    .icon-vertical-mirrored:before,
    .icon-summary-widget:before,
    .icon-stacked-area-chart {
        color: var(--mw-viztype-active);
    }
}

%warning-icon-error-occured {
    .icon-notification-warning:before {
        color: var(--error-color);
        font-size: 3.636363636363636rem;
    }

    .icon-notification-warning:hover:before {
        color: var(--error-color);
    }
}

%input-cancel-icon {
    font-weight: normal;
    appearance: none;
    width: 30px;
    height: 30px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cpolygon points='47.56 19.27 44.73 16.44 32 29.17 19.27 16.44 16.44 19.27 29.17 32 16.44 44.73 19.27 47.56 32 34.83 44.73 47.56 47.56 44.73 34.83 32 47.56 19.27' fill='white'/%3E%3C/svg%3E") no-repeat;
    filter: var(--wf-search-cancel);
}
