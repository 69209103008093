.modal-layer-container {
    z-index: 99999999 !important;

    cc-list.client-list {
        width: 16.363636363636364rem !important;
    }

    cc-date-input-select cc-list,
    cc-input-select cc-list,
    cc-date-picker-modal cc-list {
        height: 19.636363636363636rem !important;
    }

    cc-list.picklist {
        .size-medium {
            height: auto !important;
        }
    }
}

cc-tool-tip {
    z-index: 100000000 !important;
}

cc-numeric-input.no-error {
    div.error {
        display: none;
    }
}

.funds li.checkbox,
.list-browser li.checkbox {
    cc-checkbox label, span {
        color: var(--dp-weekday) !important;
    }
}
