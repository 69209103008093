// Folder Paths
$assets-images: "../images";
$assets-fonts: "../fonts";

// Fonts
$font-family: 'NewsGothicBTRoman';
$font-family-light: 'NewsGothicBTLight';
$font-family-bold: 'NewsGothicBTRomanBold';
$font-family-demi: 'NewsGothicBTDemi';
$font-icon: 'icomoon';
$font-hs-icon: 'hs-icon-ddv';

// Font sizes
$default-font-size: 11px;
$font-size-large: 2.666666666666667rem;
$font-size-normal: 2.181818181818182rem;
$font-size-medium: 1.939393939393939rem;
$font-size-medium-medium: 1.696969696969697rem;
$font-size-medium-small: 1.454545454545455rem;
$font-size-small: 1.333333333333333rem;
$font-size-very-small: 1.212121212121212rem;
$font-size-tiny: 1.090909090909091rem;
$font-size-medium-tiny: .96969696969697rem;
$font-size-very-tiny: .848484848484848rem;

$hs-navbar-height: 44px;
$app-content-workspace-height: 70px;
$expand-view: 185px;
$collapse-view: 65px;
$manage-widgets-expand-view: 370px;

$sorting: url("#{$assets-images}/sorting-light.png");
$sort-ascending: url("#{$assets-images}/sort-ascending-light.png");
$sort-descending: url("#{$assets-images}/sort-descending-light.png");

$sorting-dark: url("#{$assets-images}/sorting-dark.png");
$sort-ascending-dark: url("#{$assets-images}/sort-ascending-dark.png");
$sort-descending-dark: url("#{$assets-images}/sort-descending-dark.png");

$background-tabletP: url("#{$assets-images}/tablet.png");
$close: url("#{$assets-images}/close.png");
$desktop: "only screen and (min-width: 1025px) and (max-width: 1280px)";
$tabletL: "only screen and (max-width: 1024px) and (min-width: 768px)";

// 0 5px 11px 0 rgba(0, 0, 0, 0.58),0 4px 15px 0 rgba(0, 0, 0, 0.1)
$common-box-shadow: 0 0 5px 2px var(--modal-shadow);
