@use "./variables" as *;
@use "./mixin" as *;

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @include font-size-tiny;
    color: var(--placeholder-color);
    padding-left: 5px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    @include font-size-tiny;
    color: var(--placeholder-color);
    padding-left: 5px;
}

:-moz-placeholder {
    /* Firefox 18- */
    @include font-size-tiny;
    color: var(--placeholder-color);
    padding-left: 5px;
}
