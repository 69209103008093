@use "./variables" as *;
@use "./mixin" as *;
@use "./extend" as *;

.vis-menu {
    position: relative;
    float: right;
    right: 285px;
    z-index: 20;
}

/*-- Visualisation Dropdown Styles -- */
.visualization.dropdown-wrapper {
    position: absolute;

    .dropdown {
        min-width: 250px;
        color: var(--text-highlight);
        margin-top: 1px;
        max-height: 280px;
        overflow: auto;
        padding: 10px;
        transform: translate(0%, 0%);

        h5 {
            @include font-size-small;
            color: var(--main-text-color);
            margin-bottom: 10px;
        }

        ul {
            margin: 0 -10px;

            li {
                @include font-size-small;
                font-weight: bold;
                padding: 4px 15px;
                cursor: pointer;
                text-transform: capitalize;
                color: var(--secondary-color);

                span,
                .icon-stacked-area-chart {
                    margin: 0 5px;
                }

                label {
                    @extend %display-inline-block;
                    cursor: pointer;
                    vertical-align: bottom;
                }

                &:hover {
                    @extend %hover-active-viz;

                    label {
                        color: var(--dsd-selected-text)
                    }
                }
            }
        }
    }
}

/*-- Settings Dropdown Styles -- */
.dropdown-wrapper.setting {
    position: absolute;

    .icon-export:before,
    .icon-editcw:before {
        margin: 0;
    }

    .dropdown {
        min-width: 370px;
        color: var(--text-highlight);
        margin-top: 1px;
        height: auto;
        overflow: auto;
        padding: 0 10px;
        left: -150px;
        transform: translate(0%, 0%);
        @media #{$tabletL} {
            left: 100px;
        }

        li {
            margin: 15px 0;
            align-items: center;

            &:hover {
                background: transparent;
            }

            &.disp-flex {
                align-items: center;

                &.disabled {
                    opacity: .5;
                }
            }

            label {
                margin-left: 6px;
                color: var(--secondary-color);
                text-transform: none;
                line-height: 18px;
            }

            .label-text {
                flex-basis: 40%;
            }

            .curPoint {
                cursor: pointer;
            }

            &.update-section {
                margin: 0 -10px;
                border-bottom: 2px solid var(--input-border);
                padding: 10px;

                i.icon-text {
                    @include font-size-very-tiny;
                    color: var(--main-text-color);
                    display: block;
                    font-style: italic;
                    cursor: default;
                }

                &.redcolor {
                    label {
                        color: var(--text-red-color);
                    }

                    i.icon-text {
                        color: var(--main-text-color);
                    }
                }
            }

            .toggle-checkbox {
                text-align: right;

                label.cb-toggle {
                    margin-left: 3px;
                    margin-right: 3px;
                }

                label.text-label {
                    @extend %display-inline-block;
                    transition: .4s;
                    min-width: 55px;

                    &:first-child {
                        text-align: right;
                        margin-left: 0;
                    }

                    &.dark-text {
                        font-weight: bold;
                        color: var(--text-highlight);
                        transition: .4s;
                    }

                    &:last-child {
                        text-align: left;
                        margin-left: 0;
                    }
                }
            }

            .custom-sb {
                @include custom-sb-spec;
                padding: 3px 5px;
                border: none;
            }

            .custom-sb + span {
                @include posabs;
                left: auto;
            }

            .pos-rel {
                position: relative;
            }

            .wrap {
                flex-grow: 1;
            }
        }
    }
}
