@use './variables' as *;
@use './mixin' as  *;
@use "./extend" as *;

.formControl {
    background: var(--mw-sb-fill);
    border: 1px solid var(--mw-sb-border);
    font-weight: bold;
    width: 100%;
    padding: 5px 8px;
    color: var(--text-highlight);
    text-transform: initial;
    box-shadow: none;

    &::placeholder {
        font-weight: normal;
    }

    &:focus {
        transition: .3s;
    }

    &:disabled {
        border-color: var(--mw-sb-border);
        background: var(--mw-sb-fill);
        color: var(--disable-color);
        cursor: not-allowed;
        pointer-events: none;
        opacity: .5;
    }

    &.readonly {
        border-color: transparent;
        padding-left: 0;
        pointer-events: none;
    }
}

select::-ms-expand {
    display: none;
}

input,
select,
.custom-sb {
    border-radius: 0;
    appearance: none;
}

::-ms-clear {
    display: none;
}

.disabled-icon {
    opacity: .3;
    pointer-events: none;
}

.pull-right {
    float: right;
}

.left {
    text-align: left;
    padding-left: 5px;
}

.right {
    text-align: right;
    padding-right: 5px;
}

.center {
    text-align: center;
}

.dark-text {
    font-weight: bold;
    color: var(--text-highlight);
}

textarea.formControl {
    width: 100%;
    height: 78px;
    resize: none;
    min-width: 100%;
    max-width: 100%;
    min-height: 76px;
    max-height: 76px;
    border-radius: 0;
    appearance: none;
    overflow: auto;

    &.readonly {
        border-color: transparent;
        padding-left: 0;
        pointer-events: auto;
    }
}


.search-box {
    display: flex;
    position: relative;

    input[type="search"] {
        flex: 1;
        width: 100%;
        padding: 6px 0 6px 25px;
        font-weight: normal;

        & + button {
            margin-left: 10px;
        }
    }

    .icon-search1 {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.custom-dropdown-icon-spec {
    height: 18px;
    width: 24px;
    display: inline-block;
    cursor: pointer;
}

.redcolor {
    color: var(--error-color);
}

.minus,
.parenthesis {
    color: var(--text-highlight);
}

.dropdown-wrapper {
    @extend %display-inline-block;
    position: relative;

    .dropdown {
        padding: 20px;
        position: absolute;
        background: var(--modal-fill);
        min-width: 400px;
        box-shadow: $common-box-shadow;
        z-index: 99999;
        margin-top: 20px;

        &.right-position {
            right: 0;
        }

        &.no-padding {
            padding: 0;
        }

        &[hidden] {
            display: none;
        }
    }
}

.toolTip {
    @include font-size-medium-tiny;
    box-shadow: $common-box-shadow;
    display: block;
    fill: #939598;
    color: #939598;
    background: var(--modal-fill);
    border: 1px solid var(--input-border);
    opacity: 1;
    position: absolute;
    z-index: 7000;
}

.title-modal {
    @include font-size-very-small;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--input-border);
    color: var(--main-text-color);
}

.errormessage {
    display: block;
    @include font-size-very-small;
    color: var(--error-color);
    text-transform: none;
    margin-top: 5px;
}

.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    span {
        @include font-size-normal;
        @extend %display-inline-block;
        text-align: center;
        color: var(--no-data-text);
        font-weight: normal;
        text-transform: uppercase;
    }

    &.error {
        span {
            color: var(--text-red-color);
            opacity: .6;
        }
    }

    &.parent-height-dash-finder {
        @include calc-height(64px)
    }

    &.parent-height-widget-finder {
        @include calc-height(80px)
    }
}

.filter-parent {
    width: 30%;
    margin-top: -7px;
}

.icon-delete {
    &.disabled-icon:before {
        opacity: .3;
        pointer-events: none;
    }
}

.app-user-select {
    &.readonly {
        app-user-select {
            .select-wrap {
                .dropdown-wrapper {
                    .select-component {
                        border-color: transparent;
                        pointer-events: none;
                        padding-left: 0;

                        .selected-list {
                            span {
                                padding-right: 4px;

                                button.empty-btn {
                                    display: none;
                                }
                            }
                        }
                    }

                    .dropdown-arrow {
                        display: none;
                    }
                }
            }
        }
    }
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.div-outer-div {
    display: block;
    height: 6px;
    width: 90%;
    margin: 11px auto;
    border-radius: 3px;
    position: relative;
    background: var(--input-border);
    overflow: hidden;

    &.white {
        box-shadow: 2px 2px 6px var(--input-border);
        background: var(--main-background);
        border: 1px solid var(--input-border);
    }

    .div-percent-bar {
        display: block;
        height: 100%;
    }
}

.search-table-header .icon-search1 {
    top: 8px;
}

.inline-background {
    cursor: pointer;
}

.properties-modal-header {
    color: var(--text-highlight);
    font-size: 2rem;
    font-family: $font-family-demi;
}
