@use "./variables" as *;
@use "./mixin" as *;

.data-in-linechart .hidden,
.hide,
.show-only-tick-labels .tick line,
.show-only-tick-labels path {
    display: none;
}

.axis line,
.axis path {
    stroke: var(--main-text-color);
    stroke-width: 1;
}

.negative-axis.axis line {
    stroke: var(--main-background);
    stroke-width: 5;
}

app-vbar-chart-visualization .labels-group .inchart-label,
app-hbar-chart-visualization .labels-group .inchart-label,
app-vsbar-chart .labels-group .inchart-label {
    fill: rgba(167, 169, 172, .32);
}

.line {
    fill: none;
    stroke: var(--dsd-selected-text);
    stroke-width: 1.5px;
}

.arc,
.legend-items text,
.bar,
.drill-key text {
    cursor: pointer;
}

polyline {
    stroke-width: .5;
    stroke: var(--main-text-color);
    fill: none;
    fill-opacity: 1;
}

.area {
    opacity: 0;
    fill: none;
    stroke: var(--dsd-selected-text);
    stroke-width: 1.5px;
}

.tick text {
    @include font-size-medium-tiny;
    fill: var(--main-text-color);
}

.legend text {
    fill: #939598;
}

.legend-items .legend-type {
    fill: var(--main-background);
    stroke-width: 1.5;
    stroke: #afafaf;
    opacity: .5;
}

text {
    @include font-size-medium-tiny;
    fill: var(--main-text-color);
}

line {
    stroke: #939598;
}

.tooltip-line {
    stroke-dasharray: 4, 3;
    stroke: var(--dsd-selected-text);
    stroke-width: .5;
}

.x-gridlines,
.y-gridlines {
    stroke: var(--chart-line);
    stroke-opacity: .7;
}

.handle {
    fill: var(--dsd-selected-text);
}

.solid-line {
    stroke: var(--chart-line);
    stroke-opacity: .7;
}

.dotted-line {
    stroke: var(--chart-line);
    stroke-opacity: .7;
    stroke-dasharray: 4, 3;
}

.zoom {
    fill: none;
}

.hidden {
    opacity: 0;
}

.hr-line {
    border-top: 1px solid var(--toggle-fill);
    margin: 2px 0;

    > div {
        margin-top: 5px;
    }
}

.pie .tooltip-data label,
.bar .tooltip-data label {
    width: auto;
    margin-right: 8px;
}

.bar .tooltip-data label b {
    color: var(--text-highlight);
}

.bar {
    stroke: none;
}

.bar .tooltip-data span {
    text-align: right;
    display: inline-block;
}

.pie:before,
.bar:before,
.bar:after,
.pie:after {
    content: '';
    border: none;
    margin: 0;
}

svg .disabled {
    fill-opacity: .5;
    pointer-events: auto;
}

.data-in-linechart {
    .bold {
        .tooltip-color {
            height: 7px;
            width: 7px;
            margin-bottom: .6px;
            border-radius: 50%;
            display: inline-block;
        }

        label {
            color: var(--chart-color);
        }
    }
}

.data-in-linechart label,
.tooltip-data label {
    @include font-size-medium-tiny;
    text-transform: capitalize;
    color: var(--main-text-color);
    min-width: 55px;
    display: inline-block;

    &.base-circle-chart-header {
        color: var(--text-highlight);
    }
}

.data-in-linechart span,
.tooltip-data span {
    @include font-size-medium-tiny;
    color: var(--text-highlight);
    font-weight: 600;
}

.tooltip-data,
.data-in-linechart {
    padding: 10px;
}

.tooltip-data {
    .item-title {
        column-span: 2;
        color: var(--text-highlight);
        font-weight: bold;
    }

    .item-label {
        color: var(--main-text-color);
        text-transform: capitalize;
        padding-right: 5px;
    }

    .item-value {
        color: var(--text-highlight);
        font-weight: bold;
        text-align: right;
        padding-left: 5px;
    }
}

.brush {
    fill: var(--brush-fill);
}

.brush .overlay {
    stroke-width: 0;
}

.brush .selection {
    fill: var(--brush-selection);
    fill-opacity: 1;
    stroke: none;
}

.brush .handle {
    fill: var(--main-background);
    stroke: var(--brush-handle);
    height: 10px;
}

mark {
    color: var(--dsd-selected-text);
    background: 0 0;
}

.context .axis--x .domain,
.context .axis--x .tick line {
    stroke-width: 0;
    stroke: none;
}

.chart-area,
app-viz-wrapper {
    height: 100%;
}

.x-value text,
.y-value text {
    fill: var(--text-highlight);
}

.brush-month {
    text-transform: uppercase;
}
