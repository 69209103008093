@use "./variables" as *;
@use "./mixin" as *;

.background-tablet {
    background: $background-tabletP no-repeat;
}

// TODO Move to svg icons where possible

// All of the icons like "\e900" come from _icomoon. There is no way to figure out what is available or what these look
// like without just trying them, so good luck.

[class^="icon-"],
[class*=" icon-"],
.icon-family {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-icon !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;

    &:not(.flat) {
        cursor: pointer;
    }

    &, &:before {
        color: var(--icon-base-color);
    }

    &:hover {
        &, &:before {
            color: var(--icon-hover);
        }
    }
}

// .icon-add-visualization:before {
//     content: "\e900";
// }

.icon-add-widgets2:before {
    content: "\e902";
}

.icon-find-widgets-2:before {
    content: "\e94b";
}

.icon-advancedgrid-list:before {
    content: "\e909";
}

.icon-advancedgrid-menu:before {
    content: "\e90a";
}

.icon-advancedgrid:before {
    content: "\e90c";
}

.icon-ag-search:before {
    content: "\e90e";
}

.icon-ag-sum:before {
    content: "\e90f";
}

.icon-areachart:before {
    content: "\e910";
}

.icon-arrow-down:before {
    content: "\e911";
    font-size: .484848484848485rem;
}

.icon-arrow-left:before {
    content: "\e912";
}

.icon-arrow-right:before {
    content: "\e913";
    font-size: .727272727272727rem;
    vertical-align: middle;
}

.icon-Arrow-top:before {
    content: "\e914";
}

.icon-bigw:before {
    content: "\e915";
}

.icon-close-section:before {
    content: "\e918";
}

.icon-Close:before {
    content: "\e919";
    margin: 0 3px;
}

.icon-close1:before {
    content: "\e91a";
    font-size: .606060606060606rem;
}

.icon-collapse:before {
    content: "\e91b";
}

//.icon-collapsed:before {
//    content: "\e91c";
//}

.icon-config-close:before {
    content: "\e91d";
}

//.icon-config-search:before {
//    content: "\e91e";
//}

// .icon-dashboard-threedot:before {
//     content: "\e91f";
// }

//.icon-data-filter:before {
//    content: "\e921";
//}

//.icon-default:before {
//    content: "\e922";
//}

.icon-delete:before {
    content: "\e923";
    color: #939598;
}

.icon-desktop:before {
    content: "\e924";
}

.icon-donut:before {
    content: "\e926";
}

// .icon-downarrow:before {
//     content: "\e927";
// }

.icon-edit-master-off:before {
    content: "\e928";
}

.icon-edit-master:before {
    content: "\e929";
}

.icon-edit-refresh-off:before {
    content: "\e92a";
}

.icon-edit-refresh-on:before {
    content: "\e92b";
}

.icon-editcw:before {
    content: "\e92c";
}

//.icon-expand-view:before {
//    content: "\e92d";
//}

.icon-expand:not(cc-icon .icon-expand):before {
    content: "\e92e";
    font-size: .484848484848485rem;
    vertical-align: middle;
}

.icon-export:before {
    content: "\e92f";
    //margin: 0 7px;
}

.icon-filledfilter:before {
    content: "\e930";
}

// .icon-filter-tablet:before {
//     content: "\e931";
//     margin: 0 10px;
// }

.icon-filterarrow-close:before {
    content: "\e932";
}

.icon-filterarrow-open:before {
    content: "\e933";
}

//.icon-filter-fill:before {
//    content: "\e934";
//}

.icon-filteroutline:before {
    content: "\e935";
}

//.icon-grouper:before {
//    content: "\e936";
//}

.icon-horizontal-bar:before {
    content: "\e937";
}

.icon-horizontal-stacked:before {
    content: "\e938";
}

.icon-left-expand:before {
    content: "\e939";
}

.icon-right-expand:before {
    content: "\e948";
}

.icon-line-chart:before {
    content: "\e93a";
}

//.icon-minus:before {
//    content: "\e93b";
//}

//.icon-notification-close:before {
//    content: "\e93c";
//}

.icon-notification-error:before {
    content: "\e93d";
}

.icon-notification-info:before {
    content: "\e93e";
}

.icon-notification-success:before {
    content: "\e93f";
}

.icon-notification-warning:before {
    content: "\e940";
}

//.icon-open-section:before {
//    content: "\e941";
//}

.icon-pie-chart:before {
    content: "\e942";
}

//.icon-plus:before {
//    content: "\e943";
//}

//.icon-reset:before {
//    content: "\e944";
//}

//.icon-resize-bottom-arrow:before {
//    content: "\e945";
//}

//.icon-resize-corner-arrow:before {
//    content: "\e946";
//}

//.icon-resize-right-arrow:before {
//    content: "\e947";
//}

.icon-resize-arrow:before {
    content: "\e947";
    cursor: default;
}


.icon-search1:before {
    content: "\e949";
}

//.icon-search-widget:before {
//    content: "\e94a";
//}

//.icon-setdefault:before {
//    content: "\e94c";
//}

// .icon-setting:before {
//     content: "\e94d";
// }

//.icon-settings-filter:before {
//    content: "\e94e";
//}

//.icon-settings-notification:before {
//    content: "\e94f";
//}

.icon-settings-refresh:before {
    content: "\e950";
}

.icon-settings:before {
    content: "\e951";
}

.icon-simple-grid:before {
    content: "\e952";
}

.icon-subscribe:before {
    content: "\e953";
}

.icon-summary-widget:before {
    content: "\e954";
    margin-right: 8px;
}

.icon-tablet:before {
    content: "\e955";
}

.icon-threedots:before {
    content: "\e957";
    margin: 0 4px;
}

.icon-unsubscribe:before {
    content: "\e958";
}

.icon-vertical-bar:before {
    content: "\e959";
}

.icon-vertical-mirrored:before {
    content: "\e95a";
}

.icon-vertical-stacked:before {
    content: "\e95b";
}

//.icon-widget-settings:before {
//    content: "\e95c";
//}

//.icon-Widget:before {
//    content: "\e95d";
//}

//.icon-widgetclose:before {
//    content: "\e95e";
//}

// .icon-is-locked:before {
//     content: url("#{$assets-images}/icomoon/144-lock.svg");
//     path {
//         fill: red;
//     }
// }

.icon-close1:before:hover {
    color: #be1e2d;
}
