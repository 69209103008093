.hs-cc-theme-light {
    --main-background: #fff;
    background-color: var(--main-background);
    transition: background .2s, color .2s;

    --sidenav-background: #fff;

    --layout-border: #a7a9ac;
    --mw-layout-border: #e6e7e8;

    --main-text-color: #a7a9ac;
    --text-highlight: #000;
    --secondary-color: #808285;

    --text-red-color: #ec1e24;

    --chart-line: #d3d3d3;

    --chart-color: #333;

    --no-data-text: #00aeef;
    --no-data-text-alt: #d1d3d4;

    --relative-color: #f6f6f6;

    --remove-message-color: #808285;

    --ag-text-color: #58595b;
    --ag-group-border: #e6e7e8;
    --ag-group-fill: #f8f8f9;
    --ag-group-text: #939598;
    --ag-header-text: #434447;
    --ag-header-group-text: #a7a9ac;
    --ag-header-cell-separator: #e6eaea;
    --ag-row-hover: #e9f4fb;
    --ag-row-selected: #e9f4fb;
    --ag-cell-selected: #ebf5fa;
    --ag-row-fill-alt: #f6f6f6;
    --ag-history-link-fill: #eff9fe;
    --ag-cell-fill: #dff0fa;
    --ag-cell-text: #00aeef;
    --ag-cell-edit: rgb(238, 130, 130);
    --ag-column-arrow: #e6eaea;
    --grid-action-color: rgba(29, 42, 48, 0.5);
    --grid-action-color-hover: #00aeef;

    --button-border-hover: #6dcff6;
    --button-disabled-fill: none;
    --button-disabled-text: #e6e7e8;
    --button-disabled-border: #e6e7e8;
    --button-empty-text-hover: #00aeef;

    // ------------- LEFT NAV
    --ln-menu-icon-border: #939598;
    --ln-tile-hover-border: #ccc;
    --ln-menu-icon-hover: #00aeef;
    --ln-tile-fill: #fff;
    --ln-tile-border: #e6e7e8;
    --ln-tile-active: #00aeef;
    --ln-tile-title-selected: #939598;
    --ln-tile-icon: #a7a9ac;
    --ln-tile-icon-hover: #a7a9ac;
    --ln-tile-unsaved: #f39c13;
    --ln-bot-text-hover: #00aeef;
    // ------------- LEFT NAV


    // ------------- HS HEADER
    --hs-group-name: #a7a9ac;
    --hs-filter-fill: #f3f3f5;
    --hs-filter-text: #a7a9ac;
    --hs-filter-icon: #a7a9ac;
    --hs-filter-icon-hover: #00aeef;
    --hs-filter-icon-fill: #fff;
    --hs-group-circle-border: #ccc;
    --hs-group-circle-border-hover: #54c1ef;
    // ------------- HS HEADER


    // --------- FILTER BAR
    --filter-bubble-fill: #fff;
    --filter-bubble-border: #e6e7e8;
    --filter-bubble-border-hover: #6dcff6;
    --filter-bubble-text: #a7a9ac;
    --filter-bubble-text-hover: #00aeef;
    --filter-bubble-text-strong: #222;
    --filter-bubble-fill-unsubs: #54c1ef;
    --filter-icon-fill: #939598;
    --filter-icon-border: #939598;
    --filter-icon-fill-hover: #00aeef;
    --filter-icon-border-hover: #939598;
    // --------- FILTER BAR


    // --------- MW LEFT NAV
    --mw-border: #d1d3d4;
    --mw-viztype-fill: #f7f8f8;
    --mw-viztype-active: #00aeef;
    --mw-text: #a7a9ac;
    --mw-dsd-text: #808285;
    --mw-dsd-fill: #e7f6fd;
    --mw-sb-border: #d1d3d4;
    --mw-sb-fill: #fff;
    --drag-wrap: #f6f6f6;
    --drag-wrap-border: #ccc;
    --drag-wrap-background: #fff;
    --drag-wrap-placeholder: #808285;
    // --------- MW LEFT NAV


    // --------- FUND POPUP
    --fund-text: #808285;
    --fund-text-hover: #00aeef;
    --fund-hover: #e7f6fd;
    --fund-border: #a7a9ac;
    --fund-selected: #f6f6f6;
    // --------- FUND POPUP

    // --------- SELECTOR
    --dsd-border: #d1d3d4;
    --dsd-fill: #f8f8f9;
    --dsd-hover: #e7f6fd;
    --dsd-selected-text: #00aeef;
    // --------- SELECTOR

    // --------- DATEPICKER
    --dp-weekday: #808285;
    --dp-weekend: #d1d3d4;
    --dp-hover-fill: #00aeef;
    --dp-hover-text: #fff;
    --dp-range-fill: #f6f6f6;
    --dp-range-fill-alt: #e7f7fd;
    --dp-start-end: #a7a9ac;
    // --------- DATEPICKER

    // --------- FINDER
    --finder-fill: #f1f2f2;
    --finder-header-fill: #bebcc0;
    --finder-header-text: #f1f2f2;
    --finder-text: #808285;
    --finder-text-hover: #00aeef;
    --finder-fill-hover: #e7f6fd;
    --finder-tick-fill: #d1d3d4;
    --finder-tick-fill-check: #8bc541;
    --finder-tick-stroke: #fff;
    --finder-titlebar: #f1f2f2;
    --finder-border: #939598;
    --finder-view-border: #d1d3d4;
    --finder-text-alt: #a7a9ac;
    // --------- FINDER

    // --------- DROPDOWN
    --dd-fill: #f8f8f9;
    --dd-border: #d1d3d4;
    --dd-text: #808285;
    --dd-hover-text: #00aeef;
    --dd-hover-background: #e7f6fd;
    --ddv-dd-selected-background: #f5f5f5;
    --dd-color-border: #f1f2f2;
    // --------- DROPDOWN

    // --------- HI VIEW BOX
    --hi-example-color: #222;
    --hi-box-border-color: #caebfc;
    --hi-box-background: #e8f6fe;
    // --------- HI VIEW BOX

    // --------- HI DATES
    --hidate-color: #939598;
    --hidate-hover: #e7f6fd;
    --hidate-hover-text: #00aeef;
    --hidate-selected-background: #f6f6f6;
    --hidate-all-posted: #8ec641;
    --hidate-partial-posted: #939598;
    --hidate-none-posted: #a7a9ac;
    --hidate-tick-color: #8ec641;
    // --------- HI DATES

    // --------- WIDGET FINDER
    --wf-fill: #fff;
    --wf-more-text: #00aeef;
    --wf-border-color: rgba(250, 252, 254, 0.25);
    --wf-search-x: #d1d3d4;
    --wf-search-cancel: brightness(0.7) hue-rotate(140deg) saturate(6);
    --wf-eg-text: #000;
    --wf-btn-text: #00aeef;
    --wf-btn-fill: rgba(250, 252, 254,  0.07);
    --wf-btn-border: #6dcff6;
    --wf-btn-fill-hover: #00aeef;
    --wf-filter-text: #a7a9ac;
    --wf-content-fill: #f4f6fb;
    --wf-count-text: #939598;
    --wf-card-fill: #fff;
    --wf-card-border: transparent;
    --wf-card-title-text: #444548;
    --wf-card-text: #939598;
    --wf-group-border-color: #d1d3d4;
    --wf-card-shadow: 0 0 6px 0 var(--modal-shadow);

    --wf-no-widgets: #bcbec0;
    // --------- WIDGET FINDER

    --unsaved-color: #f39c13;
    --success-color: #71a031;
    --error-color: #be1e2d;
    --limited-color: #a8a9ac;
    --disable-color: #58595b;

    --icon-base-color: #a4a3a2;
    --icon-hover: #00aeef;
    --icon-border: #a7a9ac;
    --color-message: #2061ad;

    --input-label-text: #a7a9ac;
    --input-text: #000;
    --input-fill: #f8f8f9;
    --input-border: #d1d3d4;
    --input-placeholder-text: #a7a9ac;
    --input-border-focus: #00aeef;
    --input-cb-border: #a7a9ac;
    --input-dd-text: #808285;
    --input-dd-text-hover: #00aeef;

    --button-text: #00aeef;
    --button-text-hover: #fff;
    --button-text-alt: #808285;
    --button-border: #6dcff6;
    --button-border-alt: #00b9f2;
    --button-ghost-border: #6dcff6;
    --button-fill: #fff;

    --button-disable-text: #e6e7e8;
    --button-disable-text-alt: #fff;
    --button-disable-border-alt: #e6e7e8;

    --toggle-fill: #ccc;
    --toggle-circle: #fff;

    --modal-fill: #fff;
    --modal-shadow: rgba(55, 50, 60, .34);
    --placeholder-color: #a4a5a8;
    --global-icon: #000;

    --brush-fill: #f6f6f6;
    --brush-handle: #bcbec0;
    --brush-selection: #d7edfa;

    --condition-label: #000;

    --zoomlist-hover: #00aeef;
    --zoomlist-selected-text: #434447;
    --zoomlist-selected-background: #b3ebff;

    --header-color: #000;
    --note-background: #f8f8f9;
    --delete-note: #db1a3a;
}
