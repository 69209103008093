// TODO Ag grid provides css vars, which we should start using
// https://www.ag-grid.com/angular-data-grid/global-style-customisation-variables/

@use "./variables" as *;
@use "./mixin" as *;
@use "./icon" as *;
@use "./extend" as *;

$ag-grid-text-color: #0593bf;

:root {
    --ag-tab-min-width: 200px;
}

/* common */
:host ::ng-deep .data-grid {
    > div {
        &.widget-flex {
            display: flex;
            height: 100%;
            flex-direction: column;

            &.maximized {
                height: calc(100% - 35px);
            }
        }

        .widget-wrap {
            height: 100%;
        }

        .form-control {
            @include bdr;
            padding: 5px 6px 7px 25px;

            &:focus {
                transition: .3s;
            }
        }
    }
}

:host ::ng-deep .simple-data-grid {
    height: 100%;

    > div {
        height: 100%;

        app-data-grid {
            ag-grid-angular {
                height: 100%;

                #borderLayout_eRootPanel {
                    .ag-root {
                        border: none;
                    }

                    .ag-header {
                        font-weight: bold;
                        background: none;
                        border-bottom: none;

                        .ag-pinned-right-header {
                            border-left: none;
                        }

                        .ag-floating-top {
                            .ag-floating-top-viewport {
                                .ag-floating-top-container {
                                    .ag-cell-focus {
                                        border: none;
                                    }
                                }
                            }
                        }

                        .ag-header-viewport {
                            border-bottom: 1px solid var(--ag-header-cell-separator);

                            .ag-header-container {
                                .ag-header-row {
                                    font-weight: bold;

                                    .ag-header-cell {
                                        &.others-down-arrow {
                                            &:after {
                                                content: none !important;
                                            }
                                        }

                                        .ag-cell-label-container {
                                            .ag-header-cell-menu-button {
                                                &, &:hover {
                                                    border: none;
                                                    margin: 9px 0 6px;
                                                }

                                                .icon-advancedgrid-menu {
                                                    filter: invert(0%);
                                                    background: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .ag-body {
                        background: var(--main-background);

                        .ag-body-container {
                            .ag-cell-focus,
                            .ag-cell-no-focus {
                                border: none;
                            }

                            .cell-number {
                                text-align: right;
                                padding-right: 10px;
                            }
                        }
                    }

                    .ag-floating-bottom {
                        font-weight: bold;
                        border-top: 2px solid var(--input-border);

                        .ag-floating-bottom-viewport {
                            .ag-floating-bottom-container {
                                .ag-cell-no-focus {
                                    font-weight: bold;
                                    border: none;
                                }

                                .ag-cell-value {
                                    font-weight: bold;
                                    color: var(--text-highlight);
                                    padding-left: 8px;
                                }

                                .cell-number {
                                    font-weight: bold;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*advanced-gird-css*/
:host ::ng-deep .advanced-data-grid {
    --ag-row-group-indent-size: 20px;
    height: 100%;

    > div {
        height: 100%;

        &.widget-flex {
            display: flex;
            height: 100%;
            flex-direction: column;
        }

        .widget-wrap {
            @include calc-height(43px);
        }

        .empty-btn {
            padding: 0 2px;
        }

        .column-search-wrap {
            input {
                &:focus {
                    transition: .3s;
                }
            }
        }

        .ag-column-drop-title-bar + .ag-column-drop-list {
            .ag-column-drop-cell {
                .ag-drag-handle {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }
        }

        .ag-pinned-left-header,
        .ag-pinned-right-header {
            border-bottom: 1px solid var(--toggle-fill);
        }

        .ag-pinned-left-header {
            &, .ag-header-group-cell {
                border-right: 1px solid var(--input-border);
            }
        }

        .ag-pinned-right-header {
            &, .ag-header-group-cell {
                border-left: 1px solid var(--ag-header-cell-separator);
            }
        }

        .ag-group-expanded,
        .ag-group-contracted {
            @extend %display-inline-block;

            .icon-right-expand:before {
                @include font-size-very-tiny;
                margin: 0 5px;
                top: -2px;
                position: relative;
            }

            .icon-arrow-down:before {
                font-size: .484848484848485rem;
                margin: 0 5px;
                top: -3px;
                position: relative;
            }
        }

        .ag-pinned-left-header,
        .ag-pinned-right-header {
            .ag-header-cell {
                &.ag-header-cell-moving {
                    background: var(--relative-color);
                }
            }
        }

        .ag-bl-normal-center-row {
            .ag-bl-normal-east {
                .ag-tool-panel {
                    background: var(--main-background);
                    border: none;
                    padding-left: 10px;

                    .ag-column-group-icons {
                        opacity: 0;
                    }

                    .ag-column-select {
                        border: none;
                        margin-bottom: 10px;
                        padding-bottom: 3px;
                        border-bottom: 1px solid var(--input-border);
                        padding-left: 15px;

                        .ag-column-select-label {
                            @include font-size-medium-tiny;
                            color: var(--text-highlight);
                            vertical-align: middle;
                        }
                    }

                    .ag-column-drop-row-group {
                        border: none;
                        display: inline-block;
                        border-bottom: 2px solid var(--drag-wrap-border);
                        padding: 5px 0;
                        height: auto;

                        .ag-column-drop-icon {
                            display: none;
                        }

                        .ag-column-drop-title {
                            display: block;
                        }
                    }

                    .ag-column-drop-values,
                    .ag-column-drop-pivot {
                        @include font-size-tiny;
                        width: 100%;
                        height: auto;
                        border: none;
                        display: inline-block;
                        background: var(--main-background);
                        border-bottom: 2px solid var(--drag-wrap-border);

                        .ag-column-drop-icon {
                            display: none;
                        }

                        .ag-column-drop-title {
                            margin-bottom: 3px;
                            display: block;
                        }
                    }
                }
            }
        }

        .ag-header {
            font-weight: bold;
            border-bottom: none;

            .ag-header-container {
                min-width: 100%;
            }

            .ag-header-row {
                %separator {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    display: block;
                    width: 1px;
                    height: 18px;
                    top: 11px;
                    background: var(--ag-header-cell-separator);
                }

                .ag-header-cell {
                    &[col-id*="checkbox"],
                    &.in-compare-group {
                        &:after {
                            right: 0;
                        }
                    }

                    &[col-id*="checkbox"]:before {
                        left: 0;
                    }

                    &[col-id*="checkbox"] {
                        .ag-header-cell-comp-wrapper {
                            width: auto;
                        }
                    }
                }

                .ag-header-group-cell {
                    &:before {
                        @extend %separator;
                        left: 0;
                    }
                }
            }


            .ag-header-group-cell {
                &:before {
                    height: auto !important;
                    top: 0 !important;
                    bottom: 0;
                }
            }

            .ag-header-group-cell-no-group {
                border: 0 !important;

                &:before {
                    background: none !important;
                }
            }

            .ag-pinned-right-header {
                border-left: none;
            }

            .ag-pinned-left-header,
            .ag-pinned-right-header {
                border-bottom: 1px solid var(--toggle-fill);
            }

            .ag-pinned-left-header {
                &, .ag-header-group-cell {
                    border-right: 1px solid var(--input-border);
                }
            }

            .ag-pinned-right-header {
                &, .ag-header-group-cell {
                    border-left: 1px solid var(--input-border);
                }
            }

            .ag-pinned-left-header,
            .ag-pinned-right-header {
                .ag-header-cell {
                    &.ag-header-cell-moving {
                        background: var(--relative-color);
                    }
                }
            }

            .ag-header-viewport {
                border-bottom: 1px solid var(--ag-header-cell-separator);
            }

            .ag-floating-top {
                .ag-floating-top-viewport {
                    .ag-floating-top-container {
                        .ag-cell-focus {
                            border: none;
                        }
                    }
                }
            }

            .ag-header-row {
                .ag-header-group-cell {
                    font-weight: bold;
                    border-right-color: transparent;

                    &.ag-header-group-cell-with-group {
                        border-bottom-color: var(--input-border);
                        padding: 0 12px;

                        .ag-header-group-cell-label {
                            display: block;
                            border: 0;
                        }
                    }

                    .ag-header-group-cell-label {
                        color: var(--ag-header-group-text);
                        height: 100%;

                        .ag-header-group-text {
                            font-size: .892561983272727rem;
                        }

                        .ag-header-expand-icon {
                            padding-left: 5px;

                            .icon-arrow-right,
                            .icon-arrow-left {
                                &:before {
                                    @include font-size-very-tiny;
                                    color: var(--ag-column-arrow);
                                }
                            }
                        }
                    }
                }

                .ag-header-cell {
                    padding-top: 0;
                    padding-bottom: 3px;

                    &:hover {
                        padding-bottom: 0;
                    }

                    .ag-header-cell-label {
                        margin-top: 4px;
                        position: relative;

                        .ag-filter-icon {
                            padding: 0 1px;
                        }
                    }
                }
            }
        }

        .ag-center-cols-viewport {
            overflow: auto;
        }

        .ag-body {
            background: var(--main-background);

            .ag-body-container {
                @include font-size-very-small;
                margin-top: 5px;

                .ag-row-even,
                .ag-row-odd {
                    .ag-group-value {
                        font-style: normal;
                    }
                }

                .ag-cell-focus {
                    border: none;
                    font-style: normal;
                }

                .ag-cell-no-focus {
                    border: none;
                }

                .cell-number {
                    @include font-size-tiny;
                    text-align: right;
                    padding-right: 10px;
                }

                .ag-cell-not-inline-editing {
                    @include font-size-tiny;
                    display: inline-block;

                    &:focus {
                        outline: none;
                    }

                    .ag-row-group-indent-1 {
                        padding-left: 10px;
                        display: flex;
                        align-items: center;
                    }
                }

                .ag-row-group {
                    border-top: 1px solid var(--input-border);
                }

                .ag-row {
                    a {
                        color: var(--text-highlight);
                        text-decoration: none;

                        &.originallyUndefined {
                            font-style: italic;

                            &, &:visited {
                                color: var(--toggle-fill);
                            }
                        }

                        &:visited {
                            color: var(--text-highlight);
                        }

                        &:hover,
                        &:visited:hover {
                            color: var(--dsd-selected-text);
                            cursor: pointer;
                        }
                    }
                }

                .ag-row-group.ag-row-group-expanded {
                    border-bottom: 1px solid var(--input-border);
                }

                .ag-row-focus {
                    background: var(--dsd-selected-text);
                }
            }

            .ag-pinned-left-cols-viewport,
            .ag-pinned-right-cols-viewport {
                margin-top: 5px;

                .ag-cell {
                    border-right-color: transparent;
                }
            }

            .ag-pinned-left-cols-viewport {
                .ag-row {
                    .ag-cell-last-left-pinned {
                        border-right: 1px solid var(--input-border);
                    }
                }
            }

            .ag-pinned-right-cols-viewport {
                .ag-row {
                    .ag-cell-first-right-pinned {
                        border-left: 1px solid var(--input-border);
                        padding-left: 10px;
                    }
                }
            }
        }

        .ag-floating-bottom {
            font-weight: bold;
            border-top: 2px solid var(--input-border);
            z-index: 1;

            .ag-floating-bottom-viewport {
                .ag-floating-bottom-container {
                    .ag-cell-no-focus {
                        font-weight: bold;
                        border: none;
                        line-height: 20px;
                    }

                    .ag-cell-value {
                        @include font-size-tiny;
                        font-weight: bold;
                        color: var(--text-highlight);
                        line-height: 16px;

                        a {
                            font-weight: bold;
                            color: var(--text-highlight);
                            text-decoration: none;

                            &:visited {
                                color: var(--text-highlight);
                            }

                            &:hover,
                            &:visited:hover {
                                color: var(--dsd-selected-text);
                            }
                        }
                    }

                    .cell-number {
                        text-align: right;
                        font-weight: bold;
                    }
                }
            }
        }

        .ag-cell-no-focus,
        .ag-pinned-left-floating-bottom .ag-cell-no-focus,
        .ag-cell-focus,
        .ag-cell:focus {
            border: none;
        }

        .cell-number {
            @include font-size-tiny;
            text-align: right;
            padding-right: 10px;
        }
    }

    @include indent(10);
}

.simple-data-grid, .advanced-data-grid {
    .ag-keyboard-focus {
        .ag-header {
            .ag-header-viewport {
                .ag-header-container {
                    .ag-header-cell {
                        &:focus {
                            &::after {
                                background: none;
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .ag-tool-panel-horizontal-resize,
    .ag-side-buttons,
    .ag-column-select-header {
        display: none;
    }

    .ag-root-wrapper {
        overflow: initial;
    }

    .ag-column-drop {
        @include font-size-tiny;
        height: 35px;
        line-height: 30px;
        border-bottom: 2px solid hsla(0, 0%, 80%, .62);
        color: var(--limited-color);

        > div {
            display: inline-block;
            vertical-align: middle;
        }

        .ag-column-drop-cell-separator {
            display: inline-block;

            &:before {
                content: "\2192";
            }
        }

        .ag-column-drop-icon {
            @include font-size-tiny;
            opacity: 1;
            margin: 0;
            //TODO styles icon-family
            &:before {
                @extend .icon-family;
                opacity: 1;
                content: "\e936";
                color: var(--icon-base-color);
                margin: 0 5px;
            }
        }

        .ag-column-drop-empty-message {
            @include font-size-medium-tiny;
            color: var(--limited-color);
        }
    }

    .ag-column-drop-vertical {
        position: relative;
        border-bottom: 2px solid var(--drag-wrap-border);
    }

    .ag-column-drop-vertical .ag-column-drop-empty-message {
        @include font-size-medium-tiny;
        color: var(--limited-color);
    }

    .ag-floating-top-container,
    .ag-floating-bottom-container {
        .ag-row-even {
            &.grid-alternateshade {
                background: var(--relative-color);
            }
        }

        .ag-row-odd {
            background: var(--main-background);
        }
    }

    .ag-header {
        .ag-header-viewport {
            border-bottom: 1px solid var(--toggle-fill);
        }

        .ag-header-cell {
            @include font-size-small;
            text-align: left;
            border-right: none;
            justify-content: center;

            &.left {
                padding-left: 0;
            }

            &:hover {
                border-bottom: 3px solid var(--input-border);
            }

            &.ag-header-cell-moving {
                background: var(--relative-color);
            }

            .ag-header-cell-text {
                @include font-size-tiny();
                font-weight: bold;
                padding-left: 12px;
                position: relative;

                &:before {
                    color: var(--ag-header-text);
                }
            }

            .ag-cell-label-container {
                .icon-filteroutline {
                    &:before {
                        @include font-size-very-tiny;
                        content: "\e94e";
                        color: var(--icon-base-color);
                    }
                }
            }

            .ag-cell-label-container {
                .ag-header-cell-menu-button {
                    &, &:hover {
                        .ag-readonly & {
                            pointer-events: none;
                        }
                    }
                }
            }

            .ag-header-icon {
                &.ag-header-label-icon {
                    margin-left: 7px;
                }

                &.ag-header-cell-menu-button {
                    margin: 3px 2px 0 5px;
                }

                .icon-advancedgrid-menu {
                    background: none;
                    -webkit-filter: invert(100%);
                    filter: invert(0%);

                    &:before {
                        @include font-size-tiny;
                    }
                }
            }
        }
    }

    .ag-ltr {
        .ag-cell {
            border: none;
        }

        .ag-sort-indicator-icon {
            align-items: center;
        }
    }

    .ag-cell {
        @include font-size-tiny;

        &:hover {
            overflow-x: auto;

            .url-grid-cell,
            app-user-defined-field-cell-renderer .user-defined-field-grid-cell {
                overflow: initial !important;
            }
        }

        .ag-cell-edit-wrapper {
            input {
                background: var(--input-fill);
                border: 1px solid var(--input-border);

                &:focus {
                    border: 1px solid var(--input-border-focus);
                }
            }
        }
    }

    .ag-cell-range-selected {
        &, &.no-grid-alternateshade {
            background: var(--ag-row-selected);
        }

        &.ag-cell-range-selected-1 {
            &:not(.ag-has-focus) {
                &:not(.ag-cell-edited) {
                    background-color: var(--ag-row-selected) !important;
                }
            }
        }
    }

    .ag-column-drop-cell {
        background: var(--ag-group-fill);
        border-radius: 4px;
        padding: 3px 6px;
        border: 1px solid var(--ag-group-border);
        color: var(--ag-group-text);
        display: inline-block;
        margin: 0 5px 2px;
        position: relative;
        line-height: initial;

        .ag-column-drop-cell-text {
            margin-right: 15px;
        }

        .ag-column-drop-cell-button {
            background: $close no-repeat;
            height: 10px;
            width: 10px;
            position: absolute;
            cursor: pointer;
            z-index: 0;
            text-indent: -9999px;
            padding: 0;
            top: 5px;
            right: 5px;
            display: block;

            &:hover {
                border: 1px solid transparent;
            }
        }

        .ag-column-drop-list {
            position: relative;

            .ag-drag-handle {
                position: absolute;
                top: 0;
                right: 20px;
                bottom: 0;
                left: 0;
            }
        }
    }

    .ag-side-bar {
        @include font-size-tiny;
        flex: 0 0 200px;
        align-items: flex-start;
        overflow-y: auto;

        .ag-column-select {
            display: block;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--toggle-fill);
            padding-left: 15px;
            width: 100%;
        }

        .ag-column-drop-row-group {
            height: auto;
            line-height: initial;
            position: relative;
        }

        .ag-column-select-column-label {
            @include font-size-medium-tiny;
        }

        .ag-column-drop-title {
            color: var(--secondary-color);
        }

        .ag-column-select-checkbox {
            margin-right: 4px;
        }

        .ag-column-select-column-group,
        .ag-column-select-column {
            position: relative;
            align-items: center;

            .ag-drag-handle {
                min-width: 0;
                position: absolute;
                top: 0;
                left: 30px;
                right: 0;
                bottom: 0;

                &.ag-column-select-column-group-drag-handle {
                    left: 40px;
                }
            }

            &.ag-column-select-add-group-indent {
                .ag-column-select-column-drag-handle {
                    left: 50px;
                }
            }

            .ag-column-select-column-label {
                margin-top: 5px;
            }
        }

        .ag-column-drop {
            height: auto;
        }

        .ag-column-drop-list {
            .ag-drag-handle {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        .ag-column-drop-cell {
            display: inline-block;
        }

        .ag-column-drop-cell-button {
            z-index: 100;
        }

        .ag-column-drop-row-group .ag-column-drop-icon {
            display: none;
        }

        .ag-column-drop,
        .ag-column-select {
            .ag-resizer-wrapper {
                .ag-resizer.ag-resizer-bottom {
                    pointer-events: none !important;
                    cursor: default;
                }
            }
        }
    }

    .ag-column-drop-vertical .ag-column-drop-list {
        @include font-size-medium-tiny;
        height: auto;
        min-height: 25px;
    }

    .ag-column-panel {
        display: block;
        padding-left: 10px;
        min-height: initial;
    }

    .ag-column-select-list {
        height: auto;
    }

    .ag-pivot-mode-panel {
        background: var(--main-background);
        border-bottom: 1px solid #ddd;
        padding: 7px 0;
        width: 100%;

        .ag-checkbox-label {
            @include font-size-tiny;
            vertical-align: middle;
        }
    }

    .ag-tooltip {
        @include font-size-medium-tiny;
        border: 1px solid var(--input-border);
        border-radius: 2px;
        background: var(--modal-fill);
        padding: 3px;
        color: var(--text-highlight);
        width: 220px;

    }

    .ag-select-agg-func-popup {
        z-index: 1000;
        border: 1px solid var(--input-border);

        .ag-virtual-list-viewport {
            .ag-virtual-list-container {
                padding: 5px;
                background: var(--main-background);

                .ag-virtual-list-item {
                    @include font-size-tiny;
                    margin-bottom: 5px;
                    margin-top: 2px;

                    .ag-select-agg-func-item {
                        width: 95%;
                        padding: 0;
                        cursor: pointer;

                        &:hover {
                            background: var(--relative-color);
                        }
                    }
                }
            }
        }
    }

    .ag-group-value,
    .ag-group-child-count {
        font-weight: bold;
    }

    .ag-checkbox-input-wrapper {
        display: flex;

        .ag-checkbox-input {
            opacity: 0;
            width: 14px;
            height: 14px;
            margin: 3px 4px 0 9px;
        }

        &:after {
            display: inline-block;
            position: absolute;
            height: 12px;
            width: 12px;
            cursor: pointer;
            vertical-align: middle;
            pointer-events: none;
            border: 1px solid var(--input-border);
            content: '';
            color: var(--text-highlight);
            line-height: 9px;
            margin: 3px 9px;
            background: var(--filter-bubble-fill);
            text-align: center;
            font-weight: bold;
        }

        &.ag-checked {
            &:after {
                content: '✓';
                color: var(--text-highlight);
            }
        }

        &.ag-indeterminate {
            &:after {
                content: '—';
                font-size: .818181818181818rem;
            }
        }

    }

    .ag-toggle-button-input-wrapper {
        .ag-input-field-input {
            opacity: 1;
            border: none;
            background: none;
        }

        .ag-input-field-input:focus {
            border: none;
        }

        .ag-input-field-input:after {
            display: inline-block;
            height: 12px;
            width: 12px;
            cursor: pointer;
            vertical-align: middle;
            pointer-events: none;
            border: 1px solid var(--input-border);
            content: '';
            color: var(--text-highlight);
            line-height: 9px;
            margin-right: 9px;
            background: var(--filter-bubble-fill);
            text-align: center;
            font-weight: bold;
        }

        &.ag-checked {
            .ag-input-field-input:after {
                content: '✓';
            }
        }
    }

    .ag-icon-checkbox-checked-readonly {
        background: none;

        &:before {
            content: '✓';
            border: 1px solid var(--button-disabled-border);
            line-height: 9px;
            font-weight: bold;
            display: inline-block;
        }
    }

    .ag-cell-value {
        a {
            color: var(--text-highlight);
            text-decoration: none;

            &:visited {
                color: var(--text-highlight);
            }

            &:hover,
            &:visited:hover {
                color: var(--dsd-selected-text);
            }
        }
    }

    .ag-filter-checkbox {
        display: inline-flex;
        vertical-align: text-bottom;
    }

    .ag-icon-filter {
        background: none;
        filter: invert(0%);
    }

    .ag-row-odd,
    .ag-row-even {
        line-height: 25px;
    }

    [class*="ag-sort-"] {
        display: flex;

        &:not(.ag-sort-indicator-container) {
            flex: 0 0 12px;
            margin-left: 7px;
        }

        .ag-icon,
        .sort-ascending,
        .sort-descending {
            height: 12px;
            width: 12px;
            cursor: pointer;
        }
    }

    .ag-sort-none-icon .ag-icon-none {
        .hs-cc-theme-light & {
            background: #{$sorting} no-repeat;
        }

        .hs-cc-theme-dark & {
            background: #{$sorting-dark} no-repeat;
        }
    }

    .ag-sort-ascending-icon .sort-ascending {
        .hs-cc-theme-light & {
            background: #{$sort-ascending} no-repeat;
        }

        .hs-cc-theme-dark & {
            background: #{$sort-ascending-dark} no-repeat;
        }
    }

    .ag-sort-descending-icon .sort-descending {
        .hs-cc-theme-light & {
            background: #{$sort-descending} no-repeat;
        }

        .hs-cc-theme-dark & {
            background: #{$sort-descending-dark} no-repeat;
        }
    }

    .ag-group-cell {
        font-style: normal;
    }

    .custom-resizer {
        content: '';
        border-right: 1px dashed $ag-grid-text-color;
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .resize-border:before {
        @extend .custom-resizer;
    }

    .ag-cell {
        &[col-id="others-down-arrow"] {
            background: var(--main-background);
        }

        color: var(--ag-text-color);

        span span {
            &.redcolor {
                color: var(--error-color);
            }
        }

        &.user-defined-field-cell {
            overflow: visible;
        }
    }

    .ag-floating-bottom-container .resize-border:before {
        border: none;
    }

    .ag-cell-value a {
        color: var(--secondary-color);
    }

    .ag-floating-bottom-viewport,
    .ag-floating-top-viewport {
        .ag-cell-value {
            span {
                font-weight: bold;
                color: var(--text-highlight);
                line-height: 23px;

                &.redcolor {
                    color: var(--error-color);
                }
            }
        }
    }

    .ag-dnd-ghost {
        background: var(--modal-fill);
        color: var(--text-highlight);
        border: 1px solid var(--input-border);
    }

    .ag-overlay-no-rows-center {
        @include font-size-medium;
    }

    .bold {
        font-weight: bold;
    }

    .italic {
        font-style: italic;
    }

    .roman {
        font-weight: normal;
    }

    .ag-status-bar {
        @include font-size-tiny;
    }

    .ag-status-name-value-value {
        margin-right: 10px;
        font-weight: bold;
        color: var(--text-highlight);
    }

    .ag-filter-condition {
        justify-content: left;
        padding-left: 10px;

        .ag-label {
            cursor: pointer;

            &.ag-label-checked {
                font-weight: bold;
                color: var(--condition-label);
            }
        }

        .ag-label-align-right {
            .ag-input-field-input {
                visibility: hidden;
            }
        }
    }

    .ag-popup {
        z-index: 1000;

        .ag-select-list.ag-popup-child {
            background: var(--modal-fill);
            box-shadow: $common-box-shadow;
            cursor: pointer;

            .ag-select-list-item {
                padding: 0 4px;

                &.ag-active-item,
                &:hover {
                    background: var(--fund-hover);

                    span {
                        color: var(--input-dd-text-hover);
                    }
                }

                span {
                    @include font-size-medium-tiny;
                    color: var(--input-dd-text);
                }
            }
        }
    }

    .ag-icon-tick:before {
        content: '✓';
        color: var(--text-highlight);
        line-height: 9px;
        font-weight: 700;
    }

    .pivot-header-legend {
        height: 9px;
        width: 9px;
        vertical-align: middle;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
        flex: 0 0 9px;
    }

    // toolpanel group expand/collapse icons
    // for some reason the following are missing in ag-grid.css since we upgraded to v23
    .ag-icon-tree-open {
        display: inline-block;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIgNWg4TDYgOXoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==) center/12px 12px no-repeat;
        -webkit-filter: initial;
        filter: initial;
        height: 12px;
        width: 12px;
    }

    .ag-icon-tree-closed {
        display: inline-block;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUgMmw0IDQtNCA0eiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) center/12px 12px no-repeat;
        -webkit-filter: initial;
        filter: initial;
        height: 12px;
        width: 12px;
    }

    .ag-row-even,
    .ag-row-odd {
        &.ag-row-selected {
            &, .no-grid-alternateshade {
                background: var(--ag-row-selected);
            }
        }

        &:hover, &.ag-row-hover {
            &, .ag-cell, .no-grid-alternateshade, .ag-cell-range-selected {
                background: var(--ag-row-hover) !important;
                color: var(--ag-text-color) !important;

                &.ag-cell-edited {
                    background: var(--ag-cell-edit) !important;
                }
            }

            a {
                color: var(--ag-text-color) !important;

                &.external-app-link:hover {
                    color: $ag-grid-text-color !important;
                }
            }
        }
    }

    .ag-row-odd {
        &.grid-alternateshade {
            background: var(--ag-row-fill-alt);
        }
    }

    .ag-cell-edited {
        background-color: var(--ag-cell-edit) !important;
    }

    .external-app-link {
        cursor: pointer;

        &:hover {
            color: $ag-grid-text-color;
        }
    }

    .ag-tool-panel-wrapper {
        width: 200px;
    }

    .ag-readonly {
        .ag-tool-panel-wrapper {
            cursor: not-allowed;

            .ag-input-field-input {
                pointer-events: none;
            }
        }

        .ag-column-drop-icon,
        .ag-drag-handle,
        .ag-header-cell-menu-button {
            pointer-events: none;
        }
    }

    .ag-row-focus {
        z-index: 10;
    }

    .tooltip-title {
        [class^="icon-"], [class*=" icon-"],
        [class^="hs-icon-"], [class*=" hs-icon-"] {
            &, &:before {
                color: inherit;
                font-weight: inherit;
            }
        }
    }

    .ag-header {
        height: initial !important;
        min-height: initial !important;
        align-items: flex-start;
        overflow: initial;
        color: var(--ag-header-text);
    }

    .ag-header-row-column-group {
        height: 30px;
        max-height: 30px;
    }

    .ag-header-group-text {
        text-transform: uppercase;
    }

    .ag-pinned-left-header,
    .ag-header-viewport,
    .ag-pinned-right-header {
        .ag-header-row {
            position: relative !important;
            min-width: 100%;

            .ag-header-cell {
                line-height: initial !important;
                height: 40px;
            }
        }
    }

    .ag-header-row-column {
        .ag-pivot-on & {
            line-height: 40px;
        }
    }

    .ag-header-row-column-group {
        line-height: 30px;
        border-bottom: 1px solid var(--ag-header-cell-separator);

        &, & + .ag-header-row-column {
            top: 0 !important;
        }
    }

    .hs-icon-comment {
        position: relative;
        left: -4px;

        &:before {
            color: inherit;
        }
    }

    .ag-icon-grip,
    .ag-icon-none {
        &::before {
            content: initial;
        }
    }

    .search-wrap {
        input {
            font-weight: bold;
            background: none;
            color: var(--text-highlight);

            &::placeholder {
                font-weight: normal;
            }

            &:hover::placeholder {
                color: var(--input-dd-text-hover);
            }
        }
    }

    .ag-header-cell:focus-visible::after {
        border: 0;
    }

    .ag-header-cell-resize {
        // TODO set those provided from AG grid
        //--ag-header-column-resize-handle-width: 1px;
        //--ag-header-column-resize-handle-color: var(--ag-header-cell-separator);
        //--ag-header-column-resize-handle-height: 18px;
        //--ag-header-column-resize-handle-display: block;

        width: 6px;

        &::after {
            width: 1px;
            background: var(--ag-header-cell-separator);
            height: 18px;
            right: 4px;
        }
    }

    .ag-header-group-cell {
        .ag-header-cell-resize::after {
            width: 0;
        }
    }
}

.ag-menu {
    @include font-size-tiny;
    background: var(--modal-fill);
    cursor: default;
    width: auto;
    margin-top: 0;
    color: var(--fund-text);
    border: none;
    max-height: 250px;
    overflow: hidden;
    z-index: 9999;

    &.ag-popup-child {
        box-shadow: $common-box-shadow;
    }

    .advanced-data-grid & {
        max-height: 350px;
    }

    .ag-menu-list {
        width: 200px;
        max-width: 200px;

        .advanced-data-grid & {
            max-height: 310px;
            overflow-x: hidden;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
        }

        .ag-menu-option {
            cursor: pointer;

            &.ag-menu-option-active {
                color: var(--text-highlight);
            }

            &:hover {
                color: var(--fund-text-hover);
                background: var(--fund-hover);

                .ag-menu-option-text {
                    color: var(--fund-text-hover);
                }
            }

            .ag-menu-option-text {
                color: var(--text-highlight);

                .advanced-data-grid & {
                    padding: 0;
                    line-height: 30px;
                    text-transform: capitalize;
                }
            }

            .advanced-data-grid & {
                padding: 2px 2px 2px 6px;
                display: inline-table;
                width: 100%;

                .ag-menu-option-icon {
                    text-align: center;
                    width: 20px;
                }

                .ag-icon-small-right {
                    background: none;

                    &:before {
                        @extend .icon-family;
                        @include font-size-very-tiny;
                        content: "\E948";
                        color: var(--icon-base-color);
                    }
                }
            }
        }
    }

    .ag-tabs-header {
        border-bottom: 1px solid var(--relative-color);
        display: flex;

        .advanced-data-grid & {
            padding: 5px 4px;
        }

        .ag-tab {
            border-radius: 0;
            border: none;
            padding: 6px 13px;

            .advanced-data-grid & {
                padding: 4px 13px;
            }

            &:focus {
                outline: 0;
            }

            &.ag-tab-selected {
                border: none;

                .icon-filteroutline {
                    background: none;

                    &:before {
                        color: var(--dsd-selected-text);
                    }
                }

                .advanced-data-grid & {
                    .icon-advancedgrid-menu:before,
                    .icon-filteroutline:before,
                    .icon-advancedgrid-list:before {
                        color: var(--dsd-selected-text);
                    }
                }
            }

            .icon-filteroutline,
            .advanced-data-grid & {
                .ag-icon-filter,
                .hamburger-menu,
                .icon-advancedgrid-list {
                    background: none;
                }
            }
        }
    }

    .ag-tabs-body {
        .ag-filter {
            .ag-filter-select {
                @include font-size-medium-tiny;
                font-weight: bold;
                padding: 5px;
                margin: 10px;
                border: 1px solid var(--input-border);
                width: 90%;
                min-height: 30px;

                .ag-picker-field-wrapper {
                    border: none;
                }

            }

            .ag-filter-body {
                .ag-input-field-input {
                    background: var(--input-fill);
                    color: var(--input-text);
                    border: 1px solid var(--input-border);
                }
            }

            .ag-filter-filter {
                @include font-size-medium-tiny;
                margin: 0 10px 10px;
                width: 90%;
                min-height: 30px;
            }

            .ag-filter-header-container {
                border-bottom: 1px solid var(--input-border);
                padding: 5px;

                .advanced-data-grid & {
                    &:first-child {
                        border-bottom: 1px solid var(--input-border);
                    }
                }

                &.ag-input-text-wrapper {
                    &:before {
                        @extend .icon-family;
                        @include font-size-tiny;
                        content: "\E949";
                        color: #bbbdbf;
                        position: absolute;
                        left: 11px;
                    }
                }

                .ag-filter-filter {
                    @include font-size-tiny;
                    width: 100%;
                    border: 1px solid var(--input-border);
                    padding: 8px 0 8px 18px;
                    margin: 0;

                    .advanced-data-grid & {
                        display: block;
                        height: 30px;
                        padding: 2px 11px 0 21px;
                    }
                }
            }

            .ag-set-filter-list {
                max-height: 126px;
                width: 300px;
                overflow-x: auto;
                display: flex;
                flex-direction: column;

                .ag-virtual-list-viewport {
                    width: 100%;

                    .ag-virtual-list-container {
                        position: static;
                        margin-bottom: 10px;

                        .ag-virtual-list-item {
                            position: absolute;
                            width: 100% - 5%;
                            padding: 5px;

                            .ag-filter-value {
                                @extend %ellipsis-in-filter;
                                margin-left: 4px;
                                max-width: 1200px;
                            }

                            .ag-set-filter-item-value {
                                overflow: initial;
                                text-overflow: initial;
                            }
                        }
                    }
                }
            }

            .ag-filter-value {
                @extend %ellipsis-in-filter;
                margin-left: 4px;
            }
        }

        .advanced-data-grid & {
            .ag-menu-column-select-wrapper {
                height: auto;
                max-height: 185px;
                overflow-y: auto;
                margin: 0;

                .ag-column-select {
                    display: block;
                }

                .ag-column-select-column-group {
                    margin-bottom: 4px;
                }

                .ag-column-select-column {
                    .ag-column-select-label {
                        @extend %ellipsis-v-align-top;
                        max-width: 160px;
                        display: inline-block;
                        position: relative;
                        cursor: pointer;
                    }
                }

            }
        }
    }

    .ag-column-select-column {
        margin: 0;
        padding: 5px 0 5px 10px;

        &:hover {
            background: var(--fund-hover);
            color: var(--fund-text-hover);
        }
    }
}
