@use 'sass:math';
@use 'sass:map';
@use "./variables" as *;

$font-size-map: (
    "very-tiny": $font-size-very-tiny,
    "medium-tiny": $font-size-medium-tiny,
    "tiny": $font-size-tiny,
    "small": $font-size-small,
    "very-small": $font-size-very-small,
    "medium-small": $font-size-medium-small,
    "normal": $font-size-normal,
    "medium": $font-size-medium,
    "medium-medium": $font-size-medium-medium,
    "large": $font-size-large,
);

@mixin font-size($size, $important: null) {
    font-size: map.get($font-size-map, $size) #{$important};
}

@mixin posabs {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

@mixin bdr($bwidth: 0, $bset: solid, $color: transparent) {
    border: $bwidth $bset $color;
}

@mixin font-family-normal($important: null) {
    @include font-size("normal", $important);
}

@mixin font-size-very-tiny {
    @include font-size("very-tiny");
}

@mixin font-size-medium-tiny {
    @include font-size("medium-tiny");
}

@mixin font-size-tiny($important: null) {
    @include font-size("tiny", $important);
}

@mixin font-size-small {
    @include font-size("small");
}

@mixin font-size-very-small {
    @include font-size("very-small");
}

@mixin font-size-medium-small {
    @include font-size("medium-small");
}

@mixin font-size-normal {
    @include font-size("normal");
}

@mixin font-size-medium {
    @include font-size("medium");
}

@mixin font-size-medium-medium {
    @include font-size("medium-medium");
}

@mixin font-size-large {
    @include font-size("large");
}

@mixin calc-width($calc-area) {
    width: calc(100% - #{$calc-area});
}

@mixin calc-height($calc-area, $important: null) {
    height: calc(100% - #{$calc-area}) #{$important};
}

@mixin custom-sb-spec($size: $font-size-medium-tiny) {
    font-weight: bold;
    font-size: $size;
    width: 100%;
    border: 1px solid var(--input-border);
    background: var(--input-fill);
    color: var(--text-highlight);
    padding: 7px 22px 7px 7px;
    cursor: pointer;
    height: 30px;
}

@mixin indent($n, $i: 1) {
    @for $i from 1 through $n {
        $temp: ($i * 50);
        .ag-row-group-leaf-indent.ag-row-group-indent-#{$i} {
            padding-left: #{$temp}px;
        }
    }
}
