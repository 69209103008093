@use "./variables" as *;

%common-dialog-position {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999;
}

.modal-dialog-overlay {
    @extend %common-dialog-position;
    background: var(--main-background);
    opacity: 0;
    transition: opacity .5s;

    &.shown {
        opacity: .8;
    }
}

body.modal-dialog-open {
    overflow: hidden;
}

.modal-dialog-window {
    @extend %common-dialog-position;
    display: flex !important;
    align-items: center;
    justify-content: center;
    outline: none;
    overflow-y: auto;

    &:not(.widget-finder-popup, .overrides-dialog) .modal-dialog-content {
        padding: 20px;
    }

    .modal-dialog-document {
        overflow: visible;
        width: 90%;
        max-width: 900px;
        background: var(--modal-fill);
        max-height: 85vh;
        position: relative;
        opacity: 0;
        transition: opacity .2s, top .2s ease-out;
        top: 0;
        box-shadow: $common-box-shadow;
        z-index: 999999999;

        .modal-dialog-close {
            display: none;
        }

        .modal-dialog-content {
            overflow: visible;
            width: 100%;
            height: 100%;
            max-height: 85vh;

            .finder-popup {
                padding: 20px;
            }
        }
    }

    &.shown {
        .modal-dialog-document {
            opacity: 1;
        }
    }
}

.save-dialog .modal-dialog-document {
    width: 500px;

    .modal-dialog-content {
        overflow: auto;

        .column-formatter-popup & {
            overflow: visible;
        }
    }
}

.save-dialog.overrides-dialog .modal-dialog-document {
    .modal-dialog-content {
        overflow: inherit;
    }
}

@media (max-height: 650px) {
    .save-dialog.overrides-dialog .modal-dialog-document {
        .modal-dialog-content {
            overflow: auto;
        }
    }
}

.column-formatter-popup .modal-dialog-document {
    max-width: 500px;
}

.widget-delete-alert {
    .modal-dialog-document {
        width: 400px;
    }
}

.confirmation-popup {
    .modal-dialog-document {
        width: 400px;
    }
}

.finder-popup .modal-dialog-document {
    max-width: 700px;
    height: 500px;
}

.dashboard-finder-popup .modal-dialog-document {
    max-width: 815px;
    height: 500px;
}

.filter-popup .modal-dialog-document {
    min-height: 70px;
    width: auto;
}

@media #{$tabletL} {
    .filter-popup .modal-dialog-document {
        top: -30px;
    }
}

.export-popup .modal-dialog-document {
    min-height: 70px;
    width: auto;
}

.error-popup .modal-dialog-document {
    max-width: 500px;
}

.add-widget-popup .modal-dialog-document {
    width: 800px;
    height: 400px;
}

.widget-finder-popup .modal-dialog-document {
    width: 90%;
    max-width: initial;
    max-height: 85vh;
}

.overrides-dialog .modal-dialog-content {
    padding: 25px;
}
