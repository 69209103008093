@use "./variables" as *;
@use "./mixin" as *;

.spinner {
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%) scale(0.565);

    &, .blue-spinner, .gray-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        border-style: solid;
    }

    border-width: 4px;
    border-color: rgba(0, 0, 0, .1);

    .blue-spinner,
    .gray-spinner {
        border-width: 6px;
    }

    .blue-spinner {
        width: 75px;
        height: 75px;
        border-color: #00aeef transparent;
        animation: spinner 1.7s linear infinite;
    }

    .gray-spinner {
        width: 50px;
        height: 50px;
        border-color: transparent #ccc;
        animation: spinner_reverse 1.7s linear infinite;
    }
}

@keyframes spinner {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@-webkit-keyframes spinner {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@-ms-keyframes spinner {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes spinner_reverse {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@-webkit-keyframes spinner_reverse {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@-ms-keyframes spinner_reverse {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spinner-line-fade {
    0%, 100% {
        opacity: .22;
    }
    1% {
        opacity: 1;
    }
}

.loader-parent {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    opacity: .9;
    z-index: 9999;
    background: var(--main-background);

    .loader {
        position: inherit;
        top: calc(50% - #{$hs-navbar-height});
        left: 50%;
    }
}

.loader-parent-in-left-nav {
    @include posabs;
    width: 100%;
    height: 100%;
    opacity: .8;
    z-index: 9999;
    text-align: center;
    background: var(--main-background);
    pointer-events: none;

    .loader {
        position: absolute;
    }

    button {
        position: relative;
        top: calc(50% + 33px);
        left: 0;
        z-index: 9999;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
