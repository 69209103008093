$gray-600: rgba(250, 251, 255, .65);
$gray-500: rgba(250, 251, 255, .47);
$gray-400: rgba(250, 251, 255, .25);
$gray-300: rgba(250, 251, 255, .14);
$gray-200: rgba(250, 251, 255, .07);
$gray-100: rgba(250, 251, 255, .04);

.hs-cc-theme-dark {
    --main-background: #1f2122;
    background-color: var(--main-background);
    transition: background .2s, color .2s;

    --sidenav-background: #292a2b;

    --layout-border: transparent;
    --mw-layout-border: #{$gray-400};

    --main-text-color: #{$gray-500};
    --text-highlight: #fff;
    --secondary-color: #{$gray-500};

    --text-red-color: rgba(238, 58, 57, .65);

    --chart-line: #{$gray-300};

    --chart-color: #fff;

    --no-data-text: #4db5eb;
    --no-data-text-alt: #{$gray-400};

    --relative-color: #{$gray-200};

    --remove-message-color: rgba(250, 251, 255, 0.65);

    --ag-text-color: rgba(250, 251, 255, .75);
    --ag-group-border: #{$gray-300};
    --ag-group-fill: #{$gray-200};
    --ag-group-text: #{$gray-500};
    --ag-header-text: #fff;
    --ag-header-group-text: #{gray-500};
    --ag-header-cell-separator: #{$gray-200};
    --ag-row-hover: rgba(0, 112, 163, .2);
    --ag-row-selected: #414243;
    --ag-cell-selected: #{$gray-200};
    --ag-row-fill-alt: #{$gray-100};
    --ag-history-link-fill: rgb(29, 61, 81);
    --ag-cell-fill: rgba(0, 112, 163, .3);
    --ag-cell-text: rgb(12, 174, 228);
    --ag-cell-edit: rgb(238, 130, 130);
    --ag-column-arrow: #{$gray-300};
    --grid-action-color: #{$gray-600};
    --grid-action-color-hover: #0cb5e4;

    --button-border-hover: rgb(12, 174, 228);
    --button-disabled-fill: none;
    --button-disabled-text: #{$gray-300};
    --button-disabled-border: #{$gray-300};
    --button-empty-text-hover: rgb(12, 174, 228);

    // ------------- LEFT NAV
    --ln-menu-icon-border: #{$gray-500};
    --ln-menu-icon-hover: #{$gray-600};
    --ln-tile-hover-border: #ccc;
    --ln-tile-fill: rgb(32, 34, 35);
    --ln-tile-border: #{$gray-300};
    --ln-tile-active: rgb(12, 174, 228);
    --ln-tile-title-selected: #939598;
    --ln-tile-icon: #{$gray-400};
    --ln-tile-icon-hover: #{$gray-600};
    --ln-tile-unsaved: #f7941e;
    --ln-bot-text-hover: #fff;
    // ------------- LEFT NAV


    // ------------- HS HEADER
    --hs-group-name: #{$gray-500};
    --hs-filter-fill: #{$gray-200};
    --hs-filter-text: #{$gray-500};
    --hs-filter-icon: #{$gray-500};
    --hs-filter-icon-hover: #{$gray-600};
    --hs-filter-icon-fill: none;
    --hs-group-circle-border: #{$gray-500};
    --hs-group-circle-border-hover: #{$gray-500};
    // ------------- HS HEADER


    // ---------- FILTER BAR
    --filter-bubble-fill: #{$gray-100};
    --filter-bubble-border: #{$gray-300};
    --filter-bubble-border-hover: #05506f;
    --filter-bubble-text: #{$gray-500};
    --filter-bubble-text-hover: #13aee5;
    --filter-bubble-text-strong: #fff;
    --filter-bubble-fill-unsubs: #0caee4;
    --filter-icon-fill: #{$gray-600};
    --filter-icon-border: #{$gray-600};
    --filter-icon-fill-hover: #0caee4;
    --filter-icon-border-hover: #0caee4;
    // ---------- FILTER BAR


    // --------- MW LEFT NAV
    --mw-border: #{$gray-400};
    --mw-viztype-fill: none;
    --mw-viztype-active: rgb(19, 174, 229);
    --mw-text: #{$gray-500};
    --mw-dsd-text: #{$gray-500};
    --mw-dsd-fill: #0676a2;
    --mw-sb-border: #{$gray-200};
    --mw-sb-fill: #{$gray-100};
    --drag-wrap: #{$gray-200};
    --drag-wrap-border: #{$gray-400};
    --drag-wrap-background: #2a2b2b;
    --drag-wrap-placeholder: #{$gray-400};
    // --------- MW LEFT NAV


    // --------- FUND POPUP
    --fund-text: #{$gray-600};
    --fund-text-hover: rgb(12, 174, 228);
    --fund-hover: rgba(0, 112, 163, .2);
    --fund-border: #{$gray-400};
    --fund-selected: #{$gray-200};
    // --------- FUND POPUP

    // --------- SELECTOR
    --dsd-border: #{$gray-200};
    --dsd-fill: #{$gray-100};
    --dsd-hover: #0676a2;
    --dsd-selected-text: #00aeef;
    // --------- SELECTOR

    // --------- DATEPICKER
    --dp-weekday: #{$gray-600};
    --dp-weekend: #{$gray-400};
    --dp-hover-fill: #13aee5;
    --dp-hover-text: #fff;
    --dp-range-fill: rgba(250, 251, 255, .15);
    --dp-range-fill-alt: rgba(0, 112, 163, .2);
    --dp-start-end: #{$gray-500};
    // --------- DATEPICKER

    // --------- FINDER
    --finder-fill: #{$gray-100};
    --finder-header-fill: #{$gray-100};
    --finder-header-text: #{$gray-500};
    --finder-text: #{$gray-600};
    --finder-text-hover: #15ade4;
    --finder-fill-hover: rgba(0, 112, 163, .2);
    --finder-tick-fill: rgba(250, 251, 255, 0.14);
    --finder-tick-fill-check: #00914e;
    --finder-tick-stroke: #fff;
    --finder-titlebar: #{$gray-100};
    --finder-border: #{$gray-500};
    --finder-view-border: rgba(250, 251, 255, .14);
    --finder-text-alt: #{$gray-500};
    // --------- FINDER

    // --------- DROPDOWN
    --dd-fill: #{$gray-100};
    --dd-border: #{$gray-200};
    --dd-text: #{$gray-600};
    --dd-hover-text: #0fade4;
    --dd-hover-background: rgba(0, 112, 163, .2);
    --ddv-dd-selected-background: rgba(250, 251, 255, .07);
    --dd-color-border: rgba(250, 251, 255, 0.13);
    // --------- DROPDOWN

    // --------- HI VIEW BOX
    --hi-example-color: #fff;
    --hi-box-border-color: rgba(0, 112, 163, .4);
    --hi-box-background: rgba(0, 112, 163, .2);
    // --------- HI VIEW BOX

    // --------- HI DATES
    --hidate-color: #{$gray-600};
    --hidate-hover: rgba(0, 112, 163, .2);
    --hidate-hover-text: rgb(12, 174, 228);
    --hidate-selected-background: rgba(250, 251, 255, .07);
    --hidate-all-posted: #75a636;
    --hidate-partial-posted: #939598;
    --hidate-none-posted: rgba(250, 251, 255, .33);
    --hidate-tick-color: #75a636;
    // --------- HI DATES

    // --------- WIDGET FINDER
    --wf-fill: #1f2122;
    --wf-more-text: #1eaee4;
    --wf-border-color: rgba(250, 252, 254, 0.25);
    --wf-search-x: rgba(250, 252, 254, 0.15);
    --wf-search-cancel: brightness(0.3) hue-rotate(140deg) saturate(6);
    --wf-eg-text: #bcbec0;
    --wf-btn-text: #1eaee4;
    --wf-btn-fill: rgba(250, 252, 254,  0.07);
    --wf-btn-border: #095170;
    --wf-btn-fill-hover: #1eaee4;
    --wf-filter-text: rgba(250, 252, 254, 0.47);
    --wf-content-fill: rgba(36, 59, 71, 0.50);
    --wf-count-text: rgba(250, 252, 254, 0.47);
    --wf-card-fill: rgba(250, 252, 254, 0.16);
    --wf-card-border: #58595b;
    --wf-card-title-text: #fff;
    --wf-card-text: rgba(250, 252, 254, 0.65);
    --wf-group-border-color: rgba(250, 252, 254, 0.25);
    --wf-card-shadow: 0 0 10px 0 var(--modal-shadow);

    --wf-no-widgets: rgba(250, 252, 254, 0.25);
    // --------- WIDGET FINDER

    --unsaved-color: #f39c13;
    --success-color: #71a031;
    --error-color: #eb3838;
    --limited-color: #{$gray-400};
    --disable-color: #{$gray-400};

    --icon-base-color: #{$gray-600};
    --icon-hover: #13aee5;
    --icon-border: #{$gray-500};
    --color-message: #2061ad;

    --input-label-text: #{$gray-500};
    --input-text: #fff;
    --input-fill: #{$gray-100};
    --input-border: #{$gray-200};
    --input-placeholder-text: #a7a9ac;
    --input-border-focus: #13aee5;
    --input-cb-border: #{$gray-400};
    --input-dd-text: #{$gray-600};
    --input-dd-text-hover: rgb(12, 174, 228);

    --button-text: #13aee5;
    --button-text-hover: #fff;
    --button-text-alt: #{$gray-600};
    --button-border: #6dcff6;
    --button-border-alt: #0097dc;
    --button-ghost-border: #05506f;
    --button-fill: #1f2122;

    --button-disable-text: rgba(250, 251, 255, .20);
    --button-disable-text-alt: rgba(250, 251, 255, .30);
    --button-disable-border-alt: rgba(250, 251, 255, .11);

    --toggle-fill: #{$gray-300};
    --toggle-circle: #{$gray-500};

    --modal-fill: #38393b;
    --modal-shadow: rgba(0, 0, 0, .58);

    --placeholder-color: #555658;

    --global-icon: #{$gray-600};

    --brush-fill: rgba(250, 251, 255, 0.06);
    --brush-handle: #666666;
    --brush-selection: rgba(0, 112, 163, 0.3);

    --condition-label: #00aeef;

    --zoomlist-hover: #0cb5e4;
    --zoomlist-selected-text: #ffffff;
    --zoomlist-selected-background: #03658c;

    --header-color: #fff;
    --note-background: #38393b;
    --delete-note: #f14b6c;
}
