@use "./variables" as *;
@use "./mixin" as *;

.labeled-dropdown {
    margin: 5px;

    label {
        @include font-size-medium-tiny;
        font-weight: bold;
        display: block;
        margin-bottom: 3px;
        text-transform: uppercase;
        color: var(--mw-text);
    }

    .arrow-selector {
        position: relative; // this is needed to get the drop-down arrow
        select {
            @include font-size-medium-tiny;
            font-weight: bold;
            width: 100%;
            border: 1px solid var(--mw-border);
            color: var(--text-highlight);
            padding: 7px 22px 7px 7px;
            cursor: pointer;
            background: transparent;
        }

        .icon-arrow-down {
            position: absolute;
            pointer-events: none;
        }
    }
}
