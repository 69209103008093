@use "./variables" as *;
@use "./mixin" as *;

html, body {
    width: 100%;
    height: 100%;

    &, &.ddv-app {
        font-size: $default-font-size;
    }
}

body {
    font-family: $font-family, sans-serif;
    color: var(--main-text-color);
    overflow: hidden;
    cursor: default;

    * {
        box-sizing: border-box;
    }
}

.sidenav {
    height: 100%;
    background: var(--sidenav-background);

    &.collapsed {
        width: $collapse-view;
    }
}

.layout-wrapper {
    height: 100%;
    transition: .3s;
    overflow: hidden;

    &.collapsed {
        margin-left: $collapse-view;
    }
}

.error-message {
    margin-top: 4px;
    color: var(--error-color);
}

// TODO Typography
h1, h2, h3, h4, h5, h6,
label,
ul, li,
p {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

div,
input,
select,
button,
textarea,
a {
    &:focus {
        outline: 0;
    }
}

// TODO REMOVE
.display-inline-block {
    display: inline-block;
    vertical-align: middle;

    &.valignTop {
        vertical-align: top;
    }

    &.valignBottom {
        vertical-align: bottom;
    }
}


// TODO REMOVE!
.clearfix:before,
.clearfix:after {
    display: table;
    content: '';
}

.clearfix:after {
    clear: both;
}

.disp-flex {
    display: flex;
}

*[hidden] {
    display: none !important;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.disabled {
    pointer-events: none;

    &:not(.grid):not(.in-grid):not(.cc-widget-header-icon):not(.add-visualization-icon):not(.add-summary) {
        opacity: .4;
    }
}

.disp-table {
    width: 100%;
    display: table;

    .disp-table-cell {
        display: table-cell;
        vertical-align: middle;

        &.valignTop {
            vertical-align: top;
        }

        &.valignBottom {
            vertical-align: bottom;
        }
    }
}
